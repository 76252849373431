import { ComponentBreadcrumb, ComponentResourceChecker, TextEllipsisComponent } from 'modules/core/components';
import type { ChangeEvent } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiApple, mdiArrowLeftBottom, mdiCellphoneNfc, mdiMapMarker, mdiPencil } from '@mdi/js';
import type { EuiBasicTableColumn, EuiTableSelectionType } from '@elastic/eui';
import { EuiBadge, EuiButtonEmpty, EuiFieldSearch, EuiFormRow, EuiHealth, EuiInMemoryTable, EuiLoadingContent, EuiPopover } from '@elastic/eui';
import { HelperAuthentication, HelperFunctions } from 'helpers';
import { ColoredChromeOSIcon, IconBattery2, IconGroup2 } from 'icons';
import { IconButton } from '@mui/material';
import { ConstantColors, Mixpanel } from 'utilities';
import buildQuery from 'odata-query';
import type { APIDevicesList, APIDevices } from 'modules/operational/entities/devices';
import { DevicesList } from 'modules/operational/entities/devices';
import moment from 'moment';
import {
  ModalDeprecatedComponent,
  RemoteViewModalComponent,
  ManagementFilterComponent,
  AdvancedSearchComponent,
  ActionIconsComponent,
  EditDeviceModalComponent,
  BulkSuccessModal,
  BulkConfirmationModal,
} from 'modules/operational/components/devices';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { useNavigate } from 'react-router-dom';
import { ComponentToast } from 'components';
import type { ComponentToastProps } from 'components/toast/component-toast.contracts';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { ConstantResource } from 'modules/core/utilities';
import { BulkActionsModals } from 'modules/operational/components/devices/BulkActionsModals/BulkActionsModalsComponent';
import type { Group } from 'entities/group';
import { useTypedTranslation } from 'modules/core/hooks';
import type { APIDeviceDetails } from 'modules/operational/entities/devices/deviceDetails.entity';
import { Events, Origins } from 'modules/core/entities';
import type { ReportData } from 'modules/operational/entities/devices/reportData.entity';
import { OperationalBreadcrumbs } from '../OperationalBreadcrumbs';
import * as S from './PageDevices.style';
import type {
  DevicesCountPerManagement,
  DevicesCountPerStatus,
  PageDevicesProps,
  Management,
  OperationalSystem,
  BulkActions,
  DevicesStatus,
  DashBoardFilter,
  FeatureFlagsProps,
  SelectAllBulkActionsBody,
} from './PageDevices.contracts';
import { StatusCode } from './PageDevices.contracts';
import { HelperDevice } from './PageDevices.helper';
import type { SelectProps } from 'modules/operational/components/devices/AdvancedSearch/AdvancedSearchComponent.contracts';
import { ResourceContext } from 'modules/core/contexts';
import androidIcon from 'assets/images/android_new.png';
import { useUserContext } from 'modules/core/contexts/user';
import windowsIcon from 'assets/images/windows.png';
import { FeatureFlagCheckerComponent } from 'modules/core/components/FeatureFlagChecker/FeatureFlagCheckerComponent';
import { PngIcon } from './PageDevices.style';

export function PageDevices({ embedded }: PageDevicesProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasChrome, setHasChrome] = useState(false);
  const [toastType, setToastType] = useState<ComponentToastProps['toastType']>(undefined);
  const [isDeprecatedModalOpen, setIsDeprecatedModalOpen] = useState(false);
  const [isLoadingSearchSelect, setIsLoadingSearchSelect] = useState(false);
  const [isStatusPopoverOpen, setIsStatusPopoverOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchSelect, setSearchSelect] = useState('id');
  const [customFieldLabels, setCustomFieldLabels] = useState<SelectProps[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('Pulsus ID');
  const [activePage, setActivePage] = useState(0);
  const [devicesList, setDevicesList] = useState<DevicesList[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<string>(null!);
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useState<DashBoardFilter>('');
  const [oDataFilter, setODataFilter] = useState('');
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsProps>();
  const [selectedDevices, setSelectedDevices] = useState<DevicesList[]>([]);
  const [showRemoteViewModal, setShowRemoteViewModal] = useState(false);
  const [openActionModal, setOpenActionModal] = useState<BulkActions>('');
  const [bulkActionsPasswordError, setBulkActionsPasswordError] = useState(false);
  const [bulkActionsPasswordLoading, setBulkActionsPasswordLoading] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [countings, setCountings] = useState<{
    perManagementCount: DevicesCountPerManagement;
    perStatusCount: DevicesCountPerStatus;
  }>({ perManagementCount: { FM: 0, FM_WP: 0, WP: 0, DA: 0, SU: 0, MSU: 0, BA: 0, NU: 0, WS: 0 }, perStatusCount: {} as DevicesCountPerStatus });
  const [hasPermission, setHasPermission] = useState(false);
  const [pagination, setPagination] = useState({ total: 0, pageCount: 0 });
  const [totalRegisteredDevices, setTotalRegisteredDevices] = useState(0);
  const [isAllDevicesInPageSelected, setIsAllDevicesInPageSelected] = useState(false);
  const [isAllDevicesSelected, setIsAllDevicesSelected] = useState(false);
  const [confirmAction, setConfirmAction] = useState<BulkActions>('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFilterByInput, setIsFilterByInput] = useState(false);
  const [hasSelectedDevice, setHasSelectedDevice] = useState({
    ios: false,
    macOs: false,
    chrome: false,
    nullLauncher: false,
    android14: false,
  });

  const { t } = useTypedTranslation<'devices'>('devices');
  const history = useNavigate();
  const tableRef = useRef<EuiInMemoryTable<DevicesList>>(null);
  const mainRef = useRef<HTMLElement>(null);

  const itemsPerPage = 25;
  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);
  const resources = useContext(ResourceContext);
  const { tenant, administrator } = useUserContext();

  const chromeRemoteViewPostMessage = (identifier: string) => {
    const payload = {
      event: 'CHROME_REMOTE_VIEW',
      identifier,
    };

    window.parent.postMessage(payload, process.env.REACT_APP_RUBY_LINK!);
  };

  const columns: EuiBasicTableColumn<DevicesList>[] = [
    {
      field: 'deviceId',
      name: 'Identificador',
      align: 'center',
      render: (deviceId: DevicesList['deviceId']) => renderIdentifierColumn(deviceId),
    },
    {
      field: 'status',
      name: 'Status',
      width: '115px',
      render: (status: DevicesStatus) => renderStatusColumn(status),
    },
    {
      field: 'name',
      name: t('table.header.name'),
      render: (name: string) => renderNameColumn(name),
    },
    {
      field: 'management',
      name: t('table.header.management'),
      render: (management: DevicesList['management']) => renderManagementColumn(management),
      width: '120px',
      align: 'center',
    },
    {
      field: 'information',
      name: t('table.header.information'),
      render: (_, device: DevicesList) => renderInformationColumn(device),
    },
    {
      field: 'soVersion',
      name: t('table.header.os'),
      width: '60px',
      align: 'center',
      render: (osVersion: string) => renderOSColumn(osVersion),
    },
    {
      field: 'group',
      name: t('table.header.group'),
      render: (group: DevicesList['group']) => renderGroupColumn(group),
    },
    {
      field: 'lastSync',
      name: t('table.header.last_sync'),
      width: '95px',
    },
    {
      field: 'battery',
      name: t('table.header.battery'),
      width: '150px',
      align: 'center',
      render: (battery: DevicesList['battery']) => renderBatteryColumn(battery),
    },
    {
      field: 'deviceId',
      name: t('table.header.actions'),
      align: 'center',
      width: '100px',
      render: (deviceId: DevicesList['deviceId'], { information }) => renderActionsColumn(deviceId, information),
    },
  ];

  const renderIdentifierColumn = (deviceId: DevicesList['deviceId']) => {
    const { icon, id, os } = deviceId;
    return (
      <S.IdentifierColumn>
        <S.IdentifierLink
          href={embedded ? `${process.env.REACT_APP_PULSUS_WEB_HOST}/devices/${id}` : `/operational/device-management/${os}/${id}`}
          target="_top"
          className="link"
          onClick={() => {
            Mixpanel.track('dispositivos-acessou-dispositivo', tenant, administrator, { device_id: id });
          }}
        >
          <S.DeviceIcon>{icon}</S.DeviceIcon>
          <div className="id">
            <span>{id}</span>
          </div>
        </S.IdentifierLink>
        {hasPermission && (
          <button className="edit-icon-button link" title={t('table.edit_devices')} type="button" onClick={() => setIsEditModalOpen(id)}>
            <Icon path={mdiPencil} size={0.8} color="#67758d" />
          </button>
        )}
      </S.IdentifierColumn>
    );
  };

  const renderStatusColumn = (status: DevicesStatus) => {
    const { color, text } = HelperDevice.findStatusAttributes(status);
    return (
      <S.StatusColumn>
        <EuiHealth style={{ color }} />
        <span className="euiTableCellContent__text">{text}</span>
      </S.StatusColumn>
    );
  };

  const renderNameColumn = (name: string) => {
    return (
      <TextEllipsisComponent className="euiTableCellContent__text" title={name} rows={2}>
        {name}
      </TextEllipsisComponent>
    );
  };

  const renderManagementColumn = (management: DevicesList['management']) => {
    if (management !== 'UN') {
      const { color, text } = HelperDevice.findManagementAttributes(management);
      return (
        <S.ManagementColumn className={management === 'DA' ? 'isDAManagement' : ''} title={text} color={color}>
          <p>{text.toUpperCase()}</p>
        </S.ManagementColumn>
      );
    }
    return <p>-</p>;
  };

  const renderOSColumn = (osVersion: string) => {
    return (
      <TextEllipsisComponent className="euiTableCellContent__text" title={osVersion}>
        {osVersion}
      </TextEllipsisComponent>
    );
  };

  const renderInformationColumn = (device: DevicesList) => {
    const identifier = device?.deviceId?.os === 'chromeos' ? device?.information?.serialNumber : device?.information.identifier;

    return (
      <S.InfoColumn>
        <TextEllipsisComponent title={identifier}>{identifier}</TextEllipsisComponent>
        <TextEllipsisComponent title={device.information?.model}>{device.information?.model}</TextEllipsisComponent>
      </S.InfoColumn>
    );
  };

  const renderGroupColumn = (group: DevicesList['group']) => {
    const { name, id } = group;
    return (
      <S.GroupColumn
        className="link"
        onClick={() => {
          if (embedded) {
            window.parent.location.href = `${process.env.REACT_APP_PULSUS_WEB_HOST}/groups/${id}`;
          } else {
            history(`/operational/device-management/group/${id}`);
          }
        }}
      >
        <IconGroup2 />
        <TextEllipsisComponent title={name} rows={2}>
          {group.name}
        </TextEllipsisComponent>
      </S.GroupColumn>
    );
  };

  const renderBatteryColumn = (battery: DevicesList['battery']) => {
    const tempColor = HelperDevice.findBatteryTempColor(battery);
    return (
      <S.BatteryColumn>
        {parseInt(battery.level) > 0 ? (
          <div>
            <div className="battery-level">
              <p className="euiTableCellContent__text battery-text">{battery.level}%</p>
              <IconBattery2 width="17" height="19" color={ConstantColors.FINLAND} />
            </div>
            {featureFlags?.battery_temp && (
              <>
                {battery.temperature ? (
                  <div className="battery-temp">
                    <EuiHealth color={tempColor} />
                    <p className="euiTableCellContent__text">{battery.temperature}°</p>
                  </div>
                ) : (
                  <p className="battery-undefined euiTableCellContent__text">-</p>
                )}
              </>
            )}
          </div>
        ) : (
          <p className="battery-undefined euiTableCellContent__text">-</p>
        )}
      </S.BatteryColumn>
    );
  };

  const renderActionsColumn = (deviceId: DevicesList['deviceId'], information) => {
    const isMacOS = deviceId.os?.toLowerCase() === 'macos';
    const isIos = deviceId.os?.toLowerCase() === 'ios';
    const isWindows = deviceId.os?.toLowerCase() === 'windows';
    const isRemoteViewEnabled = featureFlags?.remote_view === true && !isWindows;

    let titleToShow = '';
    if (isMacOS) {
      titleToShow = t('actions_icons.macos_disabled');
    } else {
      titleToShow = t('table.show_location');
    }
    return (
      <S.ActionsColumn>
        <ComponentResourceChecker identifier={ConstantResource.DEVICES.REMOTE_VIEW}>
          {isRemoteViewEnabled && (
            <div title={titleToShow}>
              <S.IconButton
                disabled={isMacOS || isIos}
                title={t('table.open_remote')}
                type="button"
                onClick={() =>
                  deviceId?.os === 'chromeos' ? chromeRemoteViewPostMessage(information.identifier) : handleCheckRemoteBtn!(deviceId.id)
                }
              >
                <Icon path={mdiCellphoneNfc} size={0.7} color={ConstantColors.JORDAN} />
              </S.IconButton>
            </div>
          )}
        </ComponentResourceChecker>
        <S.IconButton type="button" onClick={() => sendPostMessageLocation(deviceId.id)}>
          <Icon path={mdiMapMarker} size={0.7} color={ConstantColors.JORDAN} />
        </S.IconButton>
        {showRemoteViewModal && <RemoteViewModalComponent onClose={() => setShowRemoteViewModal(false)} deviceId={String(deviceId.id)} />}
      </S.ActionsColumn>
    );
  };

  const getDevices = async (page: number, filterByOData?: boolean, search?: string) => {
    let result: APIDevices;

    const defaultFilter = '?$filter=status ne 3 and status ne 2 and status ne 8 and status ne 9';
    const filterQuery = filterByOData && oDataFilter ? oDataFilter : defaultFilter;

    setErrorMessage('');
    setIsLoading(true);

    try {
      if (search) {
        setIsLoadingSearchSelect(true);
        setIsFilterByInput(true);
        const hasCustomFieldSelected = customFieldLabels.some((item) => searchSelect === item.value);
        if (hasCustomFieldSelected) {
          result = await serviceLoki.getSearchedDevices('custom_field_info', search, page, searchSelect);
        } else {
          result = await serviceLoki.getSearchedDevices(searchSelect, search, page);
        }
      } else {
        setSearchValue('');
        setIsFilterByInput(false);
        result = await serviceLoki.getFilteredDevices(filterQuery, page);
      }

      window.parent.postMessage(JSON.stringify({ key: 'devices', result: result }), process.env.REACT_APP_RUBY_LINK!);

      if (result.devices.length === 0) {
        setErrorMessage(t('device_error_not_found'));

        setPagination({
          total: 0,
          pageCount: 1,
        });
      } else {
        setPagination({
          pageCount: Math.ceil(result.total / itemsPerPage),
          total: result.total,
        });

        const { custom_field_labels } = result;

        if (totalRegisteredDevices === 0) {
          setTotalRegisteredDevices(result.total);
        }

        const devicesLocal = result.devices.map((item: APIDevicesList) => {
          const deviceId: DevicesList['deviceId'] = {
            icon: HelperDevice.findIcon(item),
            id: String(item.id),
            os: item.platform ? item.platform.toLocaleLowerCase() : 'android',
          };

          const deviceInformation: DevicesList['information'] = {
            identifier: item.identifier,
            model: item.model,
            serialNumber: item.serial_number,
          };

          const deviceGroup: DevicesList['group'] = {
            name: item.group?.name || '-',
            id: String(item.group?.id) || '-',
            launcherId: item.group?.launcher_id,
          };

          const battery: DevicesList['battery'] = {
            level: item.last_heartbeat?.battery_level.toFixed(0) || '',
            temperature: item.last_heartbeat?.battery_temperature ? Math.round(item.last_heartbeat?.battery_temperature) : 0,
            isIOS: item.platform?.toLowerCase() === 'ios',
          };

          return new DevicesList(
            String(item.id),
            deviceId,
            StatusCode[item.status],
            item.user?.full_name || '-',
            item.management_type,
            deviceInformation,
            item.os_version || '-',
            deviceGroup,
            item.last_contact_at ? moment(item.last_contact_at).fromNow(true) : '-',
            battery
          );
        });

        setDevicesList(devicesLocal);

        if (custom_field_labels && featureFlags?.custom_fields) {
          const hasCustomFieldsActivated = [...resources].some((item) => item === 'custom_fields');
          if (hasCustomFieldsActivated) {
            const customFieldsAux: SelectProps[] = custom_field_labels.map((item) => {
              return { text: item.label, value: String(item.id) };
            });
            setCustomFieldLabels(customFieldsAux);
          }
        }
      }
    } catch {
      setPagination({
        total: 0,
        pageCount: 1,
      });
      setErrorMessage(t('device_error_empty'));
    } finally {
      setIsLoading(false);
      if (search && searchValue) {
        setIsLoadingSearchSelect(false);
      }
    }
  };

  const renderDevicePerPage = () => {
    const initialNumber = activePage * itemsPerPage + 1;
    const finalNumber = Math.min((activePage + 1) * itemsPerPage, pagination.total);
    const totalDevices = pagination.total;

    return (
      <>
        <strong>{totalDevices === 0 ? '0-0 ' : `${initialNumber}-${finalNumber}`}</strong>
        <span>{` ${t('of')} ${totalDevices}`}</span>
      </>
    );
  };

  const getGroups = async () => {
    try {
      const response = await serviceLoki.getGroups();
      setGroups(response);
    } catch {
      setGroups([]);
    }
  };

  const getCountings = async () => {
    try {
      const promises = [serviceLoki.getNumberOfDevicesPerManagement(), serviceLoki.getNumberOfDevicesPerStatus()];

      const [perManagementCount, perStatusCount] = await Promise.all(promises);

      const devicesPerManagement = HelperDevice.findManagementCount(perManagementCount);
      const devicesPerStatus = HelperDevice.findStatusCounter(perStatusCount);

      if (devicesPerManagement.NU > 0) {
        setHasChrome(true);
      }

      setCountings({
        perManagementCount: devicesPerManagement,
        perStatusCount: devicesPerStatus,
      });
    } catch {
      setToastType('error');
    }
  };

  const getFeatureFlags = async () => {
    const data: FeatureFlagsProps = await servicePulsusWeb.getFlags();
    setFeatureFlags(data);
  };

  const getPermission = async () => {
    try {
      const response = await serviceLoki.getPermission();
      setHasPermission(response);
    } catch {
      setHasPermission(false);
    }
  };

  const handleOnClickAction = (action: BulkActions) => {
    if (isAllDevicesSelected && !confirmAction) {
      setConfirmAction(action);
    } else if (action === 'sync') {
      handleActions(action);
    } else {
      setOpenActionModal(action);
    }
  };

  const handleCloseActionModals = () => {
    setOpenActionModal('');
    setBulkActionsPasswordError(false);
  };

  const handleActions = async (action: BulkActions, args?: string | number) => {
    let isValid = true;
    const passwordActions: BulkActions[] = ['get_phone_number', 'quarantine_mode', 'disable_quarantine', 'maintenance_mode', 'remove'];

    if (passwordActions.includes(action)) {
      setBulkActionsPasswordLoading(true);
      try {
        await servicePulsusWeb.login(HelperAuthentication.decodingEmail(), args);
        setBulkActionsPasswordError(false);
      } catch {
        isValid = false;
        setBulkActionsPasswordError(true);
      } finally {
        setBulkActionsPasswordLoading(false);
      }
    }

    const ids = selectedDevices.map((item) => {
      return item.deviceId.id;
    });

    let selectAllService: (() => Promise<void>) | undefined;

    if (isValid) {
      if (isAllDevicesSelected) {
        const defaultFilter = '?$filter=status ne 3 and status ne 2 and status ne 8 and status ne 9';
        const filterQuery = oDataFilter || defaultFilter;
        const editedFilterQuery = filterQuery.replace('?$filter=', '');
        const hasCustomFieldSelected = customFieldLabels.some((item) => searchSelect === item.value);
        const contentAttribute = 'message';

        const selectAllBody: SelectAllBulkActionsBody = {
          filter: {
            odata_filters: editedFilterQuery,
            custom_field_id: hasCustomFieldSelected ? Number(searchSelect) : undefined,
            filter_by: isFilterByInput ? searchSelect : undefined,
            search_str: isFilterByInput || hasCustomFieldSelected ? searchValue : undefined,
          },
          content:
            action === 'send_message'
              ? {
                  [contentAttribute]: args as string,
                }
              : undefined,
        };

        selectAllService = () => serviceLoki.batchSelectAll(action, selectAllBody);
      }

      const actionsServices: { [key in Exclude<BulkActions, 'disable_quarantine' | '' | 'OTAUpdate'>]: () => Promise<void> } = {
        quarantine_mode: () => serviceLoki.batchQuarantine(ids, openActionModal === 'quarantine_mode'),
        backup_contacts: () => servicePulsusWeb.batchBackupContacts(ids),
        get_phone_number: () => servicePulsusWeb.batchGetPhoneNumber(ids),
        sync: () => serviceLoki.batchUpdate(ids),
        send_message: () => serviceLoki.batchSendMessage(args as string, ids),
        renewal: () => serviceLoki.batchReregister(ids),
        update_group: () => serviceLoki.batchEditGroups(args as number, ids),
        find_phone: () => serviceLoki.batchSoundSignal(ids),
        maintenance_mode: () => serviceLoki.batchMaintenanceMode(ids),
        shutdown: () => serviceLoki.batchShutdown(ids),
        lock_device: () => serviceLoki.batchBlock(ids, args as string, !!args),
        remove: () => serviceLoki.batchRemoveDevice(ids),
      };

      try {
        const actionService = selectAllService || actionsServices[action];
        await actionService();

        if (selectAllService) {
          setIsSuccessModalOpen(true);
        }
      } catch {
        setToastType('error');
      } finally {
        setOpenActionModal('');
        setBulkActionsPasswordError(false);
        getDevices(0, true);
      }
    }
  };

  const remoteViewPostMessage = (deviceId: string) => {
    const postMessage = {
      target: 'devices_new',
      event: 'ON_CLICK',
      args: {
        type: 'remote_control',
        device_id: deviceId,
        room: `pulsus${HelperAuthentication.decodedSchemaName()} ${deviceId}`,
      },
    };

    window.parent.postMessage(JSON.stringify(postMessage), process.env.REACT_APP_RUBY_LINK!);
  };

  const handleCheckRemoteBtn = async (deviceId: string) => {
    if (deviceId) {
      remoteViewPostMessage(deviceId);
    } else {
      // TODO: use specific toast
      setToastType('error');
    }
  };

  const onChangeSearchSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const optionValue = e.target.value;
    const optionIdx = e.target.selectedIndex;
    const optionName = e.target.options[optionIdx].text;
    setSearchSelect(optionValue);

    const placeHolderOptions = {
      id: 'Pulsus ID',
      name: t('text_search.select_options.name'),
      imei: 'IMEI',
      serial_number: t('text_search.select_options.serial_number'),
      email: 'E-mail',
    };

    setSearchPlaceholder(placeHolderOptions[optionValue] || optionName || '');
  };

  const filterByInput = (search: string) => {
    getDevices(0, false, search);
    setSelectedDashboardFilter('');
    if (search === '') {
      sendReportData({ filters: null });
    } else {
      prepareReportData(undefined, true);
    }
    Mixpanel.track(`dispositivos-filtrou-dispositivos`, tenant, administrator, {
      filter: customFieldLabels.find((item) => item.value === searchSelect)?.text || searchSelect,
    });
  };

  const filterByManagement = (management: Management) => {
    if (management === 'DA') {
      setIsDeprecatedModalOpen(true);
    }

    const { filter } = HelperDevice.findManagementAttributes(management);
    setSelectedDashboardFilter(management);

    setODataFilter(buildQuery({ filter }));
    prepareReportData(management, false);
    setActivePage(0);

    Mixpanel.track(`dispositivos-filtrou-tipo-gerenciamento`, tenant, administrator, {
      management: management,
    });
  };

  const filterByOS = (os: OperationalSystem) => {
    const defaultStatusFilter = {
      and: [{ status: { ne: 0 } }, { status: { ne: 2 } }, { status: { ne: 3 } }, { status: { ne: 8 } }, { status: { ne: 9 } }],
    };
    const filter = {
      or: [
        os === 'ANDROID' && { and: [{ platform: 'Android' }, defaultStatusFilter] },
        os === 'IOS' && { and: [{ platform: 'iOS' }, defaultStatusFilter] },
        os === 'CHROMEOS' && { and: [{ platform: 'ChromeOS' }, defaultStatusFilter] },
        os === 'WINDOWS' && { and: [{ platform: 'Windows' }, defaultStatusFilter] },
      ],
    };

    setSelectedDashboardFilter(os);
    setODataFilter(buildQuery({ filter }));
    prepareReportData(os, false);
    setActivePage(0);
  };

  const deviceSelection: EuiTableSelectionType<DevicesList> = {
    onSelectionChange: (selection) => {
      setIsAllDevicesSelected(false);
      setSelectedDevices(selection);

      setHasSelectedDevice({ chrome: false, android14: false, ios: false, macOs: false, nullLauncher: false });

      selection.some((item) => {
        if (item.deviceId.os?.includes('ios')) {
          setHasSelectedDevice((prev) => ({ ...prev, ios: true }));
        }
        if (item.deviceId.os?.includes('android') && Number(item.soVersion.split('.')[0]) >= 14) {
          setHasSelectedDevice((prev) => ({ ...prev, android14: true }));
        }
        if (item.deviceId.os?.includes('chrome')) {
          setHasSelectedDevice((prev) => ({ ...prev, chrome: true }));
        }
        if (item.deviceId.os?.includes('macos')) {
          setHasSelectedDevice((prev) => ({ ...prev, macOs: true }));
        }
        if (!item.group.launcherId) {
          setHasSelectedDevice((prev) => ({ ...prev, nullLauncher: true }));
        }
      });

      if (selection.length === devicesList.length) {
        setIsAllDevicesInPageSelected(true);
      } else {
        setIsAllDevicesInPageSelected(false);
      }
    },
  };

  const clearFilters = () => {
    setActivePage(0);
    setODataFilter('');
    setSelectedDashboardFilter('');
    sendReportData({ filters: null });
  };

  const clearSelection = () => {
    if (tableRef.current) {
      tableRef.current.setSelection([]);
    }
  };

  const onPageChange = (page: number) => {
    setActivePage(page);
  };

  const sendPostMessageLocation = (deviceId: string) => {
    const postMessage = {
      target: 'devices_new',
      event: 'ON_CLICK',
      args: {
        type: 'locations',
        device_id: deviceId,
      },
    };

    window.parent.postMessage(JSON.stringify(postMessage), process.env.REACT_APP_RUBY_LINK!);
  };

  const prepareReportData = (dashBoardfilter?: DashBoardFilter, searchByInput?: boolean) => {
    let hasCustomFieldSelected = false;

    customFieldLabels.forEach((item) => {
      if (searchSelect === item.value) {
        hasCustomFieldSelected = true;
      }
    });

    const reportDataLocal: ReportData = {
      filters: {
        groups: null,
        device_identifier:
          searchValue && searchByInput
            ? {
                type: hasCustomFieldSelected ? 'custom_field' : searchSelect,
                value: searchValue,
                id: searchSelect,
              }
            : null,
        enrollmentDate: null,
        orderBy: null,
        status: null,
        lastSyncDate: null,
        activatedDate: null,
        operational_system: dashBoardfilter
          ? {
              android: ['ANDROID', 'WP', 'FM', 'FM_WP', 'DA'].includes(dashBoardfilter)
                ? {
                    checkedAndroid: true,
                    version: null,
                    management_type: ['WP', 'FM', 'FM_WP', 'DA'].includes(dashBoardfilter)
                      ? {
                          WP: dashBoardfilter === 'WP',
                          FM: dashBoardfilter === 'FM',
                          FMWP: dashBoardfilter === 'FM_WP',
                          DA: dashBoardfilter === 'DA',
                        }
                      : null,
                  }
                : null,
              ios: ['IOS', 'SU', 'BA', 'MSU'].includes(dashBoardfilter)
                ? {
                    checkedIOS: true,
                    version: null,
                    management_type: ['SU', 'BA', 'MSU'].includes(dashBoardfilter)
                      ? {
                          basic: dashBoardfilter === 'BA',
                          supervised: dashBoardfilter === 'SU',
                          macSupervised: dashBoardfilter === 'MSU',
                        }
                      : null,
                  }
                : null,
              chrome: ['CHROMEOS', 'NU'].includes(dashBoardfilter)
                ? {
                    checkedChrome: true,
                    version: null,
                    management_type: {
                      NU: dashBoardfilter === 'NU',
                      chromeOS: true,
                      flex: true,
                    },
                  }
                : null,
            }
          : null,
        available_storage: null,
        battery_level: null,
        device_with_chip: null,
      },
    };

    sendReportData(reportDataLocal);
  };

  const sendReportData = (reportData: ReportData | null) => {
    const sendPostMessage = {
      from: Origins.NJORD,
      event: Events.ON_SET_FILTERS,
      filter: reportData,
    };
    // console.log de estudo do time de dados
    console.log('reportData', reportData);
    window.parent.postMessage(JSON.stringify(sendPostMessage), process.env.REACT_APP_RUBY_LINK!);
  };

  let observerHasBeenCreated = false;

  useEffect(() => {
    const observeElement = mainRef.current;
    if (observeElement && !observerHasBeenCreated) {
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        entries.forEach((item) => {
          const postMessageHeight = {
            target: 'devices_new',
            event: 'ON_RESIZE',
            args: {
              height: item.contentBoxSize[0].blockSize < 700 ? 750 : item.contentBoxSize[0].blockSize,
            },
          };

          window.parent.postMessage(JSON.stringify(postMessageHeight), process.env.REACT_APP_RUBY_LINK!);
        });
      }).observe(observeElement);
      observerHasBeenCreated = true;
    }
  }, [mainRef.current]);

  useEffect(() => {
    if (oDataFilter) {
      getDevices(activePage, true);
    } else if (searchValue) {
      getDevices(activePage, false, searchValue);
    } else if (featureFlags) {
      getDevices(activePage);
    }
  }, [activePage, oDataFilter, featureFlags]);

  useEffect(() => {
    getCountings();
    getFeatureFlags();
    getGroups();
    getPermission();
  }, []);

  const hasCustomFieldResources = useMemo(() => [...resources].some((item) => item === 'custom_fields'), []);

  return (
    <S.Main>
      {!embedded && <ComponentBreadcrumb items={OperationalBreadcrumbs.PULSUS_OPERATIONAL_DEVICES.breadcrumbs} />}
      <S.Container ref={mainRef} id="main-container">
        <S.TopContainer>
          <S.TopLeft>
            <h2>{t('title')}</h2>
            <section className="number-devices">
              <p className="small">{`${totalRegisteredDevices} ${t('registered_devices')}`}</p>
              <EuiBadge color="#3DDB85">{countings?.perStatusCount.active}</EuiBadge>
              <div className="status-item">
                <EuiHealth style={{ color: '#3DDB85' }} />
                <p>{t('status.active')}</p>
              </div>
              <EuiPopover
                button={
                  <EuiButtonEmpty onClick={() => setIsStatusPopoverOpen(!isStatusPopoverOpen)}>
                    <EuiBadge color={ConstantColors.CROATIA}>{t('status_all')}</EuiBadge>
                  </EuiButtonEmpty>
                }
                isOpen={isStatusPopoverOpen}
                closePopover={() => setIsStatusPopoverOpen(false)}
              >
                <div className="status-list">
                  {Object.entries(countings?.perStatusCount).map(([key, value]) => {
                    const { color, text } = HelperDevice.findStatusAttributes(key as DevicesStatus);
                    if (key !== 'active') {
                      return (
                        <div key={key} style={{ display: 'grid', gridTemplateColumns: '50px auto', gap: '5px', marginBottom: '5px' }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <EuiBadge color={ConstantColors.CROATIA}>{value}</EuiBadge>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <EuiHealth style={{ color }} />
                            <p>{text}</p>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </EuiPopover>
            </section>
            <p>{t('description')}</p>
            <S.SearchBar isDisabled={isAdvancedSearchOpen}>
              <div className="text-field">
                <S.SearchSelect onChange={(e) => onChangeSearchSelect(e)}>
                  <option value="id">ID Pulsus</option>
                  <option value="name">{t('text_search.select_options.name')}</option>
                  <option value="email">E-mail</option>
                  <option value="serial_number">{t('text_search.select_options.serial_number')}</option>
                  <option value="imei">IMEI</option>
                  {customFieldLabels.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.text}
                      </option>
                    );
                  })}
                </S.SearchSelect>
                <EuiFormRow isInvalid={!HelperFunctions.inputHasOnlyNumbers(searchValue) && searchSelect === 'id'} error={t('text_search.error')}>
                  <EuiFieldSearch
                    disabled={!!openActionModal}
                    placeholder={`${t('text_search.placeholder')} ${searchPlaceholder}`}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    isLoading={isLoadingSearchSelect}
                    aria-label={t('text_search.placeholder')}
                    onSearch={(search) => filterByInput(search)}
                  />
                </EuiFormRow>
                <S.SearchAppend>
                  <S.SearchButtonIcon title="Pesquisar" onClick={() => filterByInput(searchValue)}>
                    <Icon path={mdiArrowLeftBottom} size={0.9} />
                  </S.SearchButtonIcon>
                </S.SearchAppend>
              </div>
            </S.SearchBar>
            <button id="advanced-search" type="button" className="link" onClick={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}>
              {!isAdvancedSearchOpen ? t('advanced_search.open') : t('advanced_search.close')}
            </button>
          </S.TopLeft>
          <S.TopRight isDisabled={isAdvancedSearchOpen}>
            <h4 className="title">{t('management_filter.title')}</h4>
            <S.Panel>
              <S.PanelRow>
                <S.RowIcon>
                  <S.ManagementIconOS src={androidIcon} alt="Android Icon" title="Android Icon" />
                </S.RowIcon>
                <S.RowContent>
                  {Object.entries(countings.perManagementCount).map(([key, value]) => {
                    if (['FM', 'FM_WP', 'WP', 'DA'].includes(key)) {
                      const managementKey = key as Management;
                      const { color, text, initials } = HelperDevice.findManagementAttributes(managementKey);
                      return (
                        <ManagementFilterComponent
                          key={key}
                          isSelected={selectedDashboardFilter === key}
                          onClick={() => filterByManagement(managementKey)}
                          color={color}
                          initials={initials}
                          title={text}
                          quantity={value}
                        />
                      );
                    }
                    return null;
                  })}
                </S.RowContent>
              </S.PanelRow>
              <S.PanelRow className={hasChrome ? 'divider' : ''}>
                <S.RowIcon>
                  <IconButton style={{ padding: 0 }} onClick={() => filterByOS('IOS')}>
                    <Icon path={mdiApple} title="iOS" color={ConstantColors.ITALY} size={1} />
                  </IconButton>
                </S.RowIcon>
                <S.RowContent>
                  <S.ManagementiOSLabel>
                    <div className="line" />
                    <p>iOS</p>
                    <div className="line inverted" />
                  </S.ManagementiOSLabel>
                  <S.ManagementiOSLabel macOS>
                    <div className="line" />
                    <p>macOS</p>
                    <div className="line inverted" />
                  </S.ManagementiOSLabel>
                  {Object.entries(countings.perManagementCount).map(([key, value]) => {
                    if (['SU', 'BA', 'MSU'].includes(key)) {
                      const managementKey = key as Management;
                      const { color, text, initials } = HelperDevice.findManagementAttributes(managementKey);
                      return (
                        <ManagementFilterComponent
                          key={`${key}-${initials}`}
                          isSelected={selectedDashboardFilter === key}
                          onClick={() => filterByManagement(managementKey)}
                          color={color}
                          initials={initials}
                          title={text}
                          quantity={value}
                        />
                      );
                    }
                    return null;
                  })}
                </S.RowContent>
              </S.PanelRow>
              {hasChrome && (
                <S.PanelRow>
                  <S.RowIcon>
                    <IconButton style={{ padding: 0 }} onClick={() => filterByOS('CHROMEOS')}>
                      <ColoredChromeOSIcon />
                    </IconButton>
                  </S.RowIcon>
                  <S.RowContent>
                    <ManagementFilterComponent
                      isSelected={selectedDashboardFilter === 'NU'}
                      onClick={() => filterByManagement('NU')}
                      color="#17A05D"
                      initials="NU"
                      title={t('management_filter.cloud_chrome')}
                      quantity={countings.perManagementCount.NU}
                    />
                  </S.RowContent>
                </S.PanelRow>
              )}
              <FeatureFlagCheckerComponent featureFlag="Windows_Management">
                <S.PanelRow>
                  <S.RowIcon>
                    <IconButton style={{ padding: 0 }} onClick={() => filterByOS('WINDOWS')}>
                      <PngIcon src={windowsIcon} alt="windows" title="windows" />
                    </IconButton>
                  </S.RowIcon>
                  <S.RowContent>
                    <ManagementFilterComponent
                      isSelected={selectedDashboardFilter === 'WS'}
                      onClick={() => filterByManagement('WS')}
                      color="#17A05D"
                      initials="WS"
                      title={t('management_filter.label_windows')}
                      quantity={countings.perManagementCount.WS}
                    />
                  </S.RowContent>
                </S.PanelRow>
              </FeatureFlagCheckerComponent>
            </S.Panel>
            {selectedDashboardFilter && (
              <button onClick={clearFilters} type="button" className="link">
                {t('clear_filters')}
              </button>
            )}
          </S.TopRight>
        </S.TopContainer>
        <S.AdvancedSearchContainer className={isAdvancedSearchOpen ? 'open' : 'closed'}>
          <AdvancedSearchComponent
            sendReportData={(reportData) => sendReportData(reportData)}
            filterDevices={(filter) => setODataFilter(filter)}
            isOpen={isAdvancedSearchOpen}
            groups={groups}
            customFields={customFieldLabels}
          />
        </S.AdvancedSearchContainer>
        <S.TableBox>
          <S.ActionsContainer>
            <S.Selected
              isAllDevicesSelected={
                isAllDevicesInPageSelected && featureFlags?.PEM1453_select_all_devices && !isAllDevicesSelected && pagination.pageCount > 1
              }
            >
              {selectedDevices.length > 0 && (
                <>
                  {isAllDevicesSelected ? (
                    <p>
                      {pagination.total} {t('selected_all_devices')}
                    </p>
                  ) : (
                    <p>
                      {selectedDevices.length} {t('selected_devices')}
                    </p>
                  )}
                  {isAllDevicesInPageSelected && featureFlags?.PEM1453_select_all_devices && pagination.pageCount > 1 && !isAllDevicesSelected && (
                    <EuiButtonEmpty className="link" onClick={() => setIsAllDevicesSelected(true)}>
                      {t('select_all')} {pagination.total} {t('devices')}
                    </EuiButtonEmpty>
                  )}
                  <EuiButtonEmpty className="link" onClick={clearSelection}>
                    {t('clear_selection')}
                  </EuiButtonEmpty>
                </>
              )}
            </S.Selected>
            {hasPermission && (
              <ActionIconsComponent
                hasOTAUpdate={featureFlags?.PEM2810_ota_update!}
                hasAllDevicesSelected={isAllDevicesSelected}
                onClickAction={(action: BulkActions) => handleOnClickAction(action)}
                hasDevicesSelected={!!selectedDevices.length}
                isQuarantineMode={featureFlags?.quarantine_mode}
                hasLauncherNullSelected={hasSelectedDevice.nullLauncher}
                hasIosSelected={hasSelectedDevice.ios}
                hasChromeSelected={hasSelectedDevice.chrome}
                hasMacOsSelected={hasSelectedDevice.macOs}
              />
            )}
          </S.ActionsContainer>
          <EuiInMemoryTable
            ref={tableRef}
            error={errorMessage}
            itemId="id"
            columns={columns}
            message={<EuiLoadingContent lines={10} />}
            items={isLoading ? [] : devicesList}
            isSelectable
            selection={deviceSelection}
            loading={isLoading}
          />
          <S.Pagination
            aria-label="Pagination Navigation"
            pageCount={pagination.pageCount}
            activePage={activePage}
            onPageClick={(page) => onPageChange(page)}
          />
          <S.DevicesPerPage>{renderDevicePerPage()}</S.DevicesPerPage>
        </S.TableBox>
      </S.Container>
      <ModalDeprecatedComponent isVisible={isDeprecatedModalOpen} />
      <ComponentToast dismissToast={() => setToastType(undefined)} toastType={toastType} />
      <BulkActionsModals
        handleAction={(action, args) => handleActions(action, args)}
        openModal={openActionModal}
        groups={groups}
        closeModals={handleCloseActionModals}
        bulkActionsPasswordLoading={bulkActionsPasswordLoading}
        bulkActionsPasswordError={bulkActionsPasswordError}
        hasIosSelected={hasSelectedDevice.ios}
        hasAndroid14Selected={hasSelectedDevice.android14}
      />

      {isEditModalOpen && (
        <EditDeviceModalComponent
          deviceId={isEditModalOpen}
          closeModal={() => setIsEditModalOpen(null!)}
          hasCustomFieldResource={hasCustomFieldResources}
          refreshDevicesList={() => getDevices(activePage)}
          setToastType={(type) => setToastType(type)}
          featureFlags={featureFlags}
          groups={groups}
        />
      )}

      <BulkConfirmationModal
        numberOfDevices={pagination.total}
        action={confirmAction}
        isOpen={confirmAction !== ''}
        close={() => setConfirmAction('')}
        confirmAction={(action: BulkActions) => handleOnClickAction(action)}
      />
      <BulkSuccessModal isOpen={isSuccessModalOpen} close={() => setIsSuccessModalOpen(false)} />
    </S.Main>
  );
}
