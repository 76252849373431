export type SafetyNetSettings = {
  id: string;
  enabled: boolean;
  timestamp_ms: number;
  wp_allow_activation_posture_unspecified: boolean;
  wp_allow_activation_at_risk: boolean;
  wp_allow_activation_potentially_compromised: boolean;
  wp_action_posture_unspecified: SafetyNetActions;
  wp_action_at_risk: SafetyNetActions;
  wp_action_potentially_compromised: SafetyNetActions;
  fm_allow_activation_posture_unspecified: boolean;
  fm_allow_activation_at_risk: boolean;
  fm_allow_activation_potentially_compromised: boolean;
  fm_action_posture_unspecified: SafetyNetActions;
  fm_action_at_risk: SafetyNetActions;
  fm_action_potentially_compromised: SafetyNetActions;
};

export enum SafetyNetActions {
  no_action = 0,
  delete_device = 1,
}
