import { ConstantServicesEndpoints } from 'utilities';
import { Group } from 'entities/group';
import { Application } from 'entities/app';
import { BaseServiceInstance } from './BaseServiceInstance';
import type { SafetyNetSettings } from 'pages/android-enterprise/safety-net/safety-net.contracts';

export class ServicePulsusFreyr extends BaseServiceInstance {
  constructor() {
    super(process.env.REACT_APP_URL_PULSUS_FREYR_API!);
  }

  async getGoogleTokenInfo() {
    const { data } = await this._instance.get(`${ConstantServicesEndpoints.PULSUS_FREYR.GOOGLE_LINKED_ACCOUNT}`);
    return data;
  }

  async removeGoogleToken() {
    const { status } = await this._instance.delete(`${ConstantServicesEndpoints.PULSUS_FREYR.GOOGLE_LINKED_ACCOUNT}`);
    return status;
  }

  async getDeviceGroups() {
    const { data } = await this._instance.get(`${ConstantServicesEndpoints.PULSUS_FREYR.GROUPS}`);
    return data;
  }

  async fetchAllAppsByQuery(groupPolicyId: string, query: string) {
    const { data } = await this._instance.get(
      `${ConstantServicesEndpoints.PULSUS_FREYR.GROUPS}/policy/${groupPolicyId}/applications/filter?$filter=contains(identifier,'${query}')`
    );
    return data.map((item: { id: string | number; name: string; total_devices: number }) => new Group(item.id, item.name));
  }

  async fetchAllApplication() {
    const { data } = await this._instance.get(`${ConstantServicesEndpoints.PULSUS_FREYR.APPLICATIONS}`);

    return data.map(
      (item: { name: string; identifier: string; status: string | number }) => new Application(item.name, item.identifier, item.status)
    );
  }

  async fetchAllGroupsById() {
    const { data } = await this._instance.get(`${ConstantServicesEndpoints.PULSUS_FREYR.GROUPS_BY_ID}`);

    return data.map((item: { id: string | number; name: string; total_devices: number }) => new Group(item.id, item.name));
  }

  // Nâo implementados ainda

  async getApps(schemaName = 'tenant_felipe_762', groupId: string | number) {
    const { data } = await this._instance.get(`${ConstantServicesEndpoints.PULSUS_FREYR.GROUPS}/${schemaName}/${groupId}/applications`);
    return data;
  }

  async getGoogleTokenOnly(tenantId: string) {
    let response = null;
    try {
      const { data } = await this._instance.get(`${ConstantServicesEndpoints.PULSUS_FREYR.GOOGLE_LINKED_ACCOUNT}/${tenantId}/token`);
      response = data;
    } catch {
      response = null;
    }
    return response;
  }

  async postGoogleToken(tenantId: string, body: { token: string }) {
    const { data } = await this._instance.post(`${ConstantServicesEndpoints.PULSUS_FREYR.GOOGLE_LINKED_ACCOUNT}/${tenantId}`, body);
    return data;
  }

  async getSafetyNetSettings() {
    const { data } = await this._instance.get(`${ConstantServicesEndpoints.PULSUS_FREYR.PLAY_INTEGRITY}`);

    return data;
  }

  async setSafetyNetSettings(data: SafetyNetSettings) {
    this._instance.put(`${ConstantServicesEndpoints.PULSUS_FREYR.PLAY_INTEGRITY}`, data);
  }
}
