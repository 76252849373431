import type { ReactElement } from 'react';
import type { Geofence } from 'modules/management/entities';
import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiInMemoryTable, EuiPagination, EuiSwitch, EuiToolTip } from '@elastic/eui';
import { TextEllipsisComponent } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import { useGeofenceContext } from 'modules/management/contexts/GeofenceContext';
import * as S from './GeofenceTableComponent.styles';
import { useUserContext } from 'modules/core/contexts/user';
import { HelperAuthentication } from 'helpers';

export const GeofenceTableComponent = (): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'geofence.table_fences' });

  const { loading, geofences, handleChangeSwitch, getConfigAndFences, pagination } = useGeofenceContext();

  const { permissions } = useUserContext();
  const canEditGroupPolicy = permissions?.group_policies && HelperAuthentication.decodedCanEditGroupPolicy();

  const columns: EuiBasicTableColumn<Geofence>[] = [
    {
      field: 'name',
      name: t('rows.linkedFences'),
      width: '100px',
      render: (name: string) => {
        return (
          <S.FenceName>
            <EuiToolTip position="top" content={name}>
              <TextEllipsisComponent>{name}</TextEllipsisComponent>
            </EuiToolTip>
          </S.FenceName>
        );
      },
    },
    {
      field: 'description',
      name: t('rows.description'),
      width: '140px',
      align: 'center',
      render: (description: string) => {
        return (
          <EuiToolTip position="top" content={description}>
            <S.DescriptionText>
              <TextEllipsisComponent>{description}</TextEllipsisComponent>
            </S.DescriptionText>
          </EuiToolTip>
        );
      },
    },
    {
      field: 'radius',
      name: t('rows.radius'),
      width: '40px',
      align: 'center',
      render: (raio: string) => {
        return <S.RadiusFlag>{raio}</S.RadiusFlag>;
      },
    },
    {
      field: 'latitude',
      name: t('rows.coords'),
      width: '100px',
      align: 'center',
      render: (_: string, item: Geofence) => {
        return (
          <S.CoordinatesColumn>
            <p>
              LAT:
              <span>{` ${item.latitude}`}</span>
            </p>
            <p>
              LONG:
              <span>{` ${item.longitude}`}</span>
            </p>
          </S.CoordinatesColumn>
        );
      },
    },
    {
      field: 'status',
      name: '',
      width: '150px',
      align: 'center',
      render: (_: string, item: Geofence) => {
        return (
          <EuiSwitch
            label={t('rows.switchText')}
            checked={item.status}
            onChange={(e) => handleChangeSwitch(e.target.checked, item.index as number)}
            disabled={!canEditGroupPolicy}
          />
        );
      },
    },
  ];

  const handlePagination = async (pageIndex: number): Promise<void> => {
    await getConfigAndFences(pageIndex);
  };

  return (
    <S.Container>
      <EuiInMemoryTable
        itemId="id"
        key="geofences_table"
        items={geofences}
        columns={columns}
        loading={loading}
        message={loading ? t('message.loading') : t('message.no_item')}
      />
      <EuiPagination pageCount={pagination.totalPages} activePage={pagination.activePage} onPageClick={(pageIndex) => handlePagination(pageIndex)} />
    </S.Container>
  );
};
