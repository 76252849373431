import { useEffect, useMemo, useState } from 'react';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { useTranslation } from 'react-i18next';
import { ConstantColors } from 'utilities';
import { AdvancedFlag } from 'components';
import { mdiAndroid } from '@mdi/js';
import type { SafetyNetProps } from '../page-android-enterprise.contracts';
import * as style from './safety-net.style';
import { SafetyNetActions, type SafetyNetSettings } from './safety-net.contracts';
import { ConstantResource } from '../../../modules/core/utilities';
import { ComponentResourceChecker } from '../../../modules/core/components';
import { ServicePulsusFreyr } from 'services';

export function SafetyNet({ saveConfig, isEditable }: Readonly<SafetyNetProps>) {
  const { t } = useTranslation();
  const [isSafetyNetOn, setIsSafetyNetOn] = useState(true);
  const [isWPEnrollOn, setIsWPEnrollOn] = useState({ unspecified: false, atRisk: false, potentiallyCompromised: false });
  const [isFMEnrollOn, setIsFMEnrollOn] = useState({ unspecified: false, atRisk: false, potentiallyCompromised: false });
  const [dataFetched, setDataFetched] = useState(false);
  const [settingsId, setSettingsId] = useState('');
  const [verificationPeriod, setVerificationPeriod] = useState('verification_24');
  const [radioWPUnspecifiedIdSelected, setRadioWPUnspecifiedIdSelected] = useState('wp_unspecified_no_action');
  const [radioWPAtRiskIdSelected, setRadioWPAtRiskIdSelected] = useState('wp_at_risk_no_action');
  const [radioWPPotentiallyCompromisedIdSelected, setRadioWPPotentiallyCompromisedIdSelected] = useState('wp_compromised_no_action');
  const [radioFMUnspecifiedIdSelected, setRadioFMUnspecifiedIdSelected] = useState('fm_unspecified_no_action');
  const [radioFMAtRiskIdSelected, setRadioFMAtRiskIdSelected] = useState('fm_at_risk_no_action');
  const [radioFMPotentiallyCompromisedIdSelected, setRadioFMPotentiallyCompromisedIdSelected] = useState('fm_potentially_compromised_no_action');

  const serviceFreyr = useMemo(() => new ServicePulsusFreyr(), []);

  const msMultiplier = 3600000;

  const radiosWP = {
    unspecified: [
      {
        id: 'wp_unspecified_no_action',
        label: t('components.component_safetynet.inline_buttons_label.nothing'),
      },
      {
        id: 'wp_unspecified_delete_device',
        label: t('components.component_safetynet.inline_buttons_label.work_profile_wipe'),
      },
    ],
    atRisk: [
      {
        id: 'wp_at_risk_no_action',
        label: t('components.component_safetynet.inline_buttons_label.nothing'),
      },
      {
        id: 'wp_at_risk_delete_device',
        label: t('components.component_safetynet.inline_buttons_label.work_profile_wipe'),
      },
    ],
    potentiallyCompromised: [
      {
        id: 'wp_potentially_compromised_no_action',
        label: t('components.component_safetynet.inline_buttons_label.nothing'),
      },
      {
        id: 'wp_potentially_compromised_delete_device',
        label: t('components.component_safetynet.inline_buttons_label.work_profile_wipe'),
      },
    ],
  };

  const radiosFM = {
    unspecified: [
      {
        id: 'fm_unspecified_no_action',
        label: t('components.component_safetynet.inline_buttons_label.nothing'),
      },
      {
        id: 'fm_unspecified_delete_device',
        label: t('components.component_safetynet.inline_buttons_label.fully_managed_wipe'),
      },
    ],
    atRisk: [
      {
        id: 'fm_at_risk_no_action',
        label: t('components.component_safetynet.inline_buttons_label.nothing'),
      },
      {
        id: 'fm_at_risk_delete_device',
        label: t('components.component_safetynet.inline_buttons_label.fully_managed_wipe'),
      },
    ],
    potentiallyCompromised: [
      {
        id: 'fm_potentially_compromised_no_action',
        label: t('components.component_safetynet.inline_buttons_label.nothing'),
      },
      {
        id: 'fm_potentially_compromised_delete_device',
        label: t('components.component_safetynet.inline_buttons_label.fully_managed_wipe'),
      },
    ],
  };

  const periodOptions = [
    {
      id: 'verification_24',
      label: t('components.component_safetynet.button.period_24_option'),
    },
    {
      id: 'verification_12',
      label: t('components.component_safetynet.button.period_12_option'),
    },
    {
      id: 'verification_6',
      label: t('components.component_safetynet.button.period_6_option'),
    },
    {
      id: 'verification_2',
      label: t('components.component_safetynet.button.period_2_option'),
    },
  ];

  const saveSafetyNetConfig = () => {
    const wpUnspecifiedAction = radioWPUnspecifiedIdSelected.includes('no_action') ? 0 : 1;
    const wpAtRiskAction = radioWPAtRiskIdSelected.includes('no_action') ? 0 : 1;
    const wpCompromisedAction = radioWPPotentiallyCompromisedIdSelected.includes('no_action') ? 0 : 1;
    const fmUnspecifiedAction = radioFMUnspecifiedIdSelected.includes('no_action') ? 0 : 1;
    const fmAtRiskAction = radioFMAtRiskIdSelected.includes('no_action') ? 0 : 1;
    const fmCompromisedAction = radioFMPotentiallyCompromisedIdSelected.includes('no_action') ? 0 : 1;

    const body: SafetyNetSettings = {
      id: settingsId,
      enabled: isSafetyNetOn,
      timestamp_ms: Number(verificationPeriod.split('_')[1]) * msMultiplier,
      wp_allow_activation_posture_unspecified: isWPEnrollOn.unspecified,
      wp_allow_activation_at_risk: isWPEnrollOn.atRisk,
      wp_allow_activation_potentially_compromised: isWPEnrollOn.potentiallyCompromised,
      wp_action_posture_unspecified: wpUnspecifiedAction,
      wp_action_at_risk: wpAtRiskAction,
      wp_action_potentially_compromised: wpCompromisedAction,
      fm_allow_activation_posture_unspecified: isFMEnrollOn.unspecified,
      fm_allow_activation_at_risk: isFMEnrollOn.atRisk,
      fm_allow_activation_potentially_compromised: isFMEnrollOn.potentiallyCompromised,
      fm_action_posture_unspecified: fmUnspecifiedAction,
      fm_action_at_risk: fmAtRiskAction,
      fm_action_potentially_compromised: fmCompromisedAction,
    };

    console.log('body', body);

    serviceFreyr.setSafetyNetSettings(body);
  };

  async function getSafetyNetConfig() {
    const data: SafetyNetSettings = await serviceFreyr.getSafetyNetSettings();

    if (data) {
      setDataFetched(true);
      setIsSafetyNetOn(data.enabled);
      setSettingsId(String(data.id));
      setVerificationPeriod(`verification_${data.timestamp_ms / msMultiplier}`);

      setIsWPEnrollOn({
        unspecified: data.wp_allow_activation_posture_unspecified,
        atRisk: data.wp_allow_activation_at_risk,
        potentiallyCompromised: data.wp_allow_activation_potentially_compromised,
      });

      setIsFMEnrollOn({
        unspecified: data.fm_allow_activation_posture_unspecified,
        atRisk: data.fm_allow_activation_at_risk,
        potentiallyCompromised: data.fm_allow_activation_potentially_compromised,
      });

      setRadioWPUnspecifiedIdSelected(`wp_unspecified_${SafetyNetActions[data.wp_action_posture_unspecified]}`);
      setRadioWPAtRiskIdSelected(`wp_at_risk_${SafetyNetActions[data.wp_action_at_risk]}`);
      setRadioWPPotentiallyCompromisedIdSelected(`wp_potentially_compromised_${SafetyNetActions[data.wp_action_potentially_compromised]}`);

      setRadioFMUnspecifiedIdSelected(`fm_unspecified_${SafetyNetActions[data.fm_action_posture_unspecified]}`);
      setRadioFMAtRiskIdSelected(`fm_at_risk_${SafetyNetActions[data.fm_action_at_risk]}`);
      setRadioFMPotentiallyCompromisedIdSelected(`fm_potentially_compromised_${SafetyNetActions[data.fm_action_potentially_compromised]}`);
    }
  }

  useEffect(() => {
    getSafetyNetConfig();
  }, []);

  useEffect(() => {
    if (saveConfig) {
      saveSafetyNetConfig();
    }
  }, [saveConfig]);

  return (
    <ComponentResourceChecker identifier={ConstantResource.ANDROID_ENTERPRISE.PLAY_INTEGRITY}>
      {dataFetched && (
        <style.Content>
          <style.TopContainer>
            <style.TopLeft>
              <h4>{t('components.component_safetynet.text.title')}</h4>
              <style.SafetyNetSwitch
                data-testid="safety-net-check"
                label={t(
                  isSafetyNetOn
                    ? 'components.component_safetynet.button.safetynet_enabled'
                    : 'components.component_safetynet.button.safetynet_disabled'
                )}
                disabled={!isEditable}
                checked={isSafetyNetOn}
                onChange={(e) => setIsSafetyNetOn(e.target.checked)}
              />
              <p>{t('components.component_safetynet.text.safetynet_verify_description')}</p>
            </style.TopLeft>
            <style.TopRight>
              <p>{t('components.component_safetynet.text.period_label')}</p>
              <style.GroupButton
                legend=""
                data-testid="safety-net-period"
                isDisabled={!isSafetyNetOn || !isEditable}
                id="settingsManagementPeriod"
                idSelected={verificationPeriod}
                onChange={(e) => setVerificationPeriod(e)}
                options={periodOptions}
              />
            </style.TopRight>
          </style.TopContainer>
          <AdvancedFlag
            flags={[
              { managementType: 'FM', icon: mdiAndroid, version: '5.0' },
              { managementType: 'WP', icon: mdiAndroid, version: '5.0' },
              { managementType: 'FM + WP', icon: mdiAndroid, version: '5.0' },
            ]}
          />
          <style.BottomContainer data-testid="safety-net-table">
            <div className="vertical-line" />
            {/* Work Profile */}
            <style.ManagementTitle className="bottom-border">
              <div className="icon-container">
                <WorkOutlinedIcon className="wp-icon" style={{ color: ConstantColors.FRANCE }} />
                <p className="management-name">Work Profile</p>
              </div>
              <span className="column-name">{t('components.component_safetynet.column.enroll_activation')}</span>
              <span className="column-name">
                {t('components.component_safetynet.column.prefix_device_integrity')}
                <span className="theme-color">{t('components.component_safetynet.column.device_integrity')}</span>
              </span>
            </style.ManagementTitle>

            <style.Options className="bottom-border">
              <p className="options-title">{t('components.component_safetynet.actions.unspecified')}</p>
              <style.OptionsSwitch
                data-testid="safety-net-wp-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isWPEnrollOn.unspecified
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isWPEnrollOn.unspecified}
                onChange={(e) => setIsWPEnrollOn({ ...isWPEnrollOn, unspecified: e.target.checked })}
              />
              <style.OptionsRadios
                data-testid="safety-net-wp-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosWP.unspecified}
                idSelected={radioWPUnspecifiedIdSelected}
                onChange={(id) => setRadioWPUnspecifiedIdSelected(id)}
                name="radio group WP unspecified"
              />
            </style.Options>

            <style.Options className="bottom-border">
              <p className="options-title">{t('components.component_safetynet.actions.at_risk')}</p>
              <style.OptionsSwitch
                data-testid="safety-net-wp-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isWPEnrollOn.atRisk
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isWPEnrollOn.atRisk}
                onChange={(e) => setIsWPEnrollOn({ ...isWPEnrollOn, atRisk: e.target.checked })}
              />
              <style.OptionsRadios
                data-testid="safety-net-wp-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosWP.atRisk}
                idSelected={radioWPAtRiskIdSelected}
                onChange={(id) => setRadioWPAtRiskIdSelected(id)}
                name="radio group WP at risk"
              />
            </style.Options>

            <style.Options className="bottom-border">
              <p className="options-title">{t('components.component_safetynet.actions.potentially_compromised')}</p>
              <style.OptionsSwitch
                data-testid="safety-net-wp-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isWPEnrollOn.potentiallyCompromised
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isWPEnrollOn.potentiallyCompromised}
                onChange={(e) => setIsWPEnrollOn({ ...isWPEnrollOn, potentiallyCompromised: e.target.checked })}
              />
              <style.OptionsRadios
                data-testid="safety-net-wp-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosWP.potentiallyCompromised}
                idSelected={radioWPPotentiallyCompromisedIdSelected}
                onChange={(id) => setRadioWPPotentiallyCompromisedIdSelected(id)}
                name="radio group WP potentially compromised"
              />
            </style.Options>

            {/* Fully Managed */}
            <style.ManagementTitle className="bottom-border">
              <div className="icon-container">
                <div className="fm-icon">
                  <PhoneAndroidIcon fontSize="small" style={{ color: ConstantColors.AUSTRIA }} />
                </div>
                <p className="management-name">Fully Managed Device</p>
              </div>
            </style.ManagementTitle>

            <style.Options className="bottom-border">
              <p className="options-title">{t('components.component_safetynet.actions.unspecified')}</p>
              <style.OptionsSwitch
                data-testid="safety-net-wp-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isFMEnrollOn.unspecified
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isFMEnrollOn.unspecified}
                onChange={(e) => setIsFMEnrollOn({ ...isFMEnrollOn, unspecified: e.target.checked })}
              />
              <style.OptionsRadios
                data-testid="safety-net-wp-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosFM.unspecified}
                idSelected={radioFMUnspecifiedIdSelected}
                onChange={(id) => setRadioFMUnspecifiedIdSelected(id)}
                name="radio group FM unspecified"
              />
            </style.Options>

            <style.Options className="bottom-border">
              <p className="options-title">{t('components.component_safetynet.actions.at_risk')}</p>
              <style.OptionsSwitch
                data-testid="safety-net-wp-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isFMEnrollOn.atRisk
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isFMEnrollOn.atRisk}
                onChange={(e) => setIsFMEnrollOn({ ...isFMEnrollOn, atRisk: e.target.checked })}
              />
              <style.OptionsRadios
                data-testid="safety-net-wp-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosFM.atRisk}
                idSelected={radioFMAtRiskIdSelected}
                onChange={(id) => setRadioFMAtRiskIdSelected(id)}
                name="radio group FM at risk"
              />
            </style.Options>

            <style.Options className="bottom-border">
              <p className="options-title">{t('components.component_safetynet.actions.potentially_compromised')}</p>
              <style.OptionsSwitch
                data-testid="safety-net-wp-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isFMEnrollOn.potentiallyCompromised
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isFMEnrollOn.potentiallyCompromised}
                onChange={(e) => setIsFMEnrollOn({ ...isFMEnrollOn, potentiallyCompromised: e.target.checked })}
              />
              <style.OptionsRadios
                data-testid="safety-net-wp-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosFM.potentiallyCompromised}
                idSelected={radioFMPotentiallyCompromisedIdSelected}
                onChange={(id) => setRadioFMPotentiallyCompromisedIdSelected(id)}
                name="radio group FM potentially compromised"
              />
            </style.Options>
          </style.BottomContainer>
        </style.Content>
      )}
    </ComponentResourceChecker>
  );
}
