import { useEffect, useState } from 'react';
import { EuiButton, EuiButtonIcon, EuiEmptyPrompt, EuiFieldNumber, EuiFieldText, EuiInMemoryTable, EuiRadio } from '@elastic/eui';
import _ from 'lodash';
import { QuickSettingsCard } from 'components/setting-card/component-quick-setting-card';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import { AdvancedFlag } from 'components/flags-advanced/component-advanced-flag';
import { mdiAndroid } from '@mdi/js';
import type { AppManagementItemProps, FullyManagedCardGroupProps } from 'components/fm-card-settings/component-fm-card-settings.contracts';
import * as style from './ComponentFmCardContent.style';

import { ComponentResourceChecker } from '../../modules/core/components';
import { ConstantResource } from '../../modules/core/utilities';
import { useUserContext } from 'modules/core/contexts/user/provider';
import { HelperAuthentication } from 'helpers';

export function FullyCardContent(props: FullyManagedCardGroupProps) {
  const { t } = useTranslation();
  const query = useQuery();
  const { permissions } = useUserContext();
  const canEditGroupPolicy = permissions?.group_policies && HelperAuthentication.decodedCanEditGroupPolicy();
  const {
    cameraDisabled,
    screenCaptureDisabled,
    maxDaysWithWorkOff,
    personalPlayStoreManagement,
    onChangeBoolean,
    onChangeNumber,
    handleChangePlayStoreManagement,
  } = props;
  const [identifier, setIdentifier] = useState('');
  const [selection, setSelection] = useState([]);
  const message = (
    <EuiEmptyPrompt
      title={<h3>{t('components.component_support_message.component_fm_card_content.apps_management_table_title')}</h3>}
      titleSize="xs"
      body={t('components.component_support_message.component_fm_card_content.apps_management_table_placeholder')}
    />
  );
  const [pagination] = useState({
    initialPageSize: 5,
    pageSizeOptions: [3, 5, 10],
  });

  const [list, setList] = useState<AppManagementItemProps[]>([]);

  const handleChange = (value: number | number[]) => {
    onChangeNumber(value as number, 'maxDaysWithWorkOffValue');
  };

  const selectionValue = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectable: (row: any) => row.id,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelectionChange: (selectionAux: any) => {
      setSelection(selectionAux);
    },
  };

  function addApp() {
    const applicationList = JSON.parse(JSON.stringify(personalPlayStoreManagement.personalApplications));
    if (_.indexOf(applicationList, identifier) < 0) {
      applicationList.push(identifier);
      handleChangePlayStoreManagement(applicationList, 'apps');
    }
  }

  function deleteApp(item: AppManagementItemProps) {
    const applicationList = JSON.parse(JSON.stringify(personalPlayStoreManagement.personalApplications));
    const index = _.indexOf(applicationList, item.id);
    applicationList.splice(index, index + 1);
    handleChangePlayStoreManagement(applicationList, 'apps');
  }

  const cols = [
    {
      field: 'identifier',
      name: 'Identifier',
      width: '176px',
    },
    {
      field: 'permission',
      name: t('components.component_support_message.component_fm_card_content.apps_management_table_col_permission'),
      width: '60px',
      render: () => {
        if (personalPlayStoreManagement.personalPlayStoreMode === 'BLOCKLIST') {
          return <div className="blockFlagCol">BLOCK</div>;
        }
        return <div className="allowFlagCol">ALLOW</div>;
      },
    },
    {
      field: 'delete',
      name: t('components.component_support_message.component_fm_card_content.apps_management_table_col_delete'),
      width: '39px',
      render: (name: string, item: AppManagementItemProps) => {
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <EuiButtonIcon onClick={() => deleteApp(item)} color="text" iconType="trash" />
          </div>
        );
      },
    },
  ];

  function removeSelected() {
    const applicationList = JSON.parse(JSON.stringify(personalPlayStoreManagement.personalApplications));
    selection.forEach((item: AppManagementItemProps) => {
      const index = _.indexOf(applicationList, item.id);
      applicationList.splice(index, index + 1);
    });
    handleChangePlayStoreManagement(applicationList, 'apps');
  }

  useEffect(() => {
    const auxList: AppManagementItemProps[] = [];
    personalPlayStoreManagement?.personalApplications?.forEach((item) => {
      auxList.push({ id: item, identifier: item });
    });
    setList(auxList);
  }, [personalPlayStoreManagement.personalApplications]);

  useEffect(() => {
    const language = query.get('language');
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <style.Container className="fullyManaged" data-testid="fully-component-content">
      <style.Content>
        <QuickSettingsCard
          test_id="fully-camera-disabled"
          title={t('components.component_support_message.component_fm_card_content.camera_disabled_title')}
          label={t('components.component_support_message.component_fm_card_content.camera_disabled_label')}
          active={cameraDisabled}
          onChange={() => onChangeBoolean(!cameraDisabled, 'deviceCamera')}
          noBoxShadow
          disabled={!canEditGroupPolicy}
        >
          <style.CardDescription>
            {t('components.component_support_message.component_fm_card_content.camera_disabled_description')}
          </style.CardDescription>
          <AdvancedFlag flags={[{ managementType: 'FM + WP', icon: mdiAndroid, version: '11.0', OS: 'Android' }]} />
        </QuickSettingsCard>

        <ComponentResourceChecker identifier={ConstantResource.POLICIES.OFF_LIMIT}>
          <QuickSettingsCard
            test_id="fully-work-off-disabled"
            title={t('components.component_support_message.component_fm_card_content.work_off_period_title')}
            label={t('components.component_support_message.component_fm_card_content.work_off_period_label')}
            active={maxDaysWithWorkOff.active}
            onChange={() => {
              onChangeBoolean(!maxDaysWithWorkOff.active, 'maxDaysWithWorkOffActive');
            }}
            noBottomPadding
            noBoxShadow
            disabled={!canEditGroupPolicy}
          />
          <style.SliderBox>
            <div style={{ width: '70%' }}>
              <style.FullySlider
                data-testid="fully-period-slider"
                disabled={!maxDaysWithWorkOff.active || !canEditGroupPolicy}
                min={3}
                max={30}
                value={maxDaysWithWorkOff.value}
                onChange={(event, value) => handleChange(value)}
              />
            </div>
            <div style={{ width: '20%' }}>
              <EuiFieldNumber
                data-testid="fully-period-input"
                disabled={!maxDaysWithWorkOff.active || !canEditGroupPolicy}
                compressed
                min={3}
                max={30}
                value={maxDaysWithWorkOff.value}
                onChange={(e) => handleChange(Number(e.target.value))}
              />
            </div>
          </style.SliderBox>
          <style.SliderBoxWarning>
            <style.CardDescription>
              {t('components.component_support_message.component_fm_card_content.work_off_period_warning_prefix')}
            </style.CardDescription>
            <span>3</span>
            <style.CardDescription>
              {t('components.component_support_message.component_fm_card_content.work_off_period_warning_sufix')}
            </style.CardDescription>
          </style.SliderBoxWarning>
          <style.SliderBox>
            <style.CardDescription>
              {t('components.component_support_message.component_fm_card_content.work_off_period_description')}
            </style.CardDescription>
          </style.SliderBox>
          <style.FlagBox data-testid="fully-flags-group">
            <AdvancedFlag flags={[{ managementType: 'FM + WP', icon: mdiAndroid, version: '11.0', OS: 'Android' }]} />
          </style.FlagBox>
        </ComponentResourceChecker>

        <QuickSettingsCard
          test_id="fully-screenshot-disabled"
          title={t('components.component_support_message.component_fm_card_content.screenshot_title')}
          label={t('components.component_support_message.component_fm_card_content.screenshot_label')}
          active={screenCaptureDisabled}
          onChange={() => onChangeBoolean(!screenCaptureDisabled, 'screenshot')}
          noBoxShadow
          disabled={!canEditGroupPolicy}
        >
          <style.CardDescription>{t('components.component_support_message.component_fm_card_content.screenshot_description')}</style.CardDescription>
          <AdvancedFlag flags={[{ managementType: 'FM + WP', icon: mdiAndroid, version: '11.0', OS: 'Android' }]} />
        </QuickSettingsCard>
      </style.Content>
      <ComponentResourceChecker identifier={ConstantResource.POLICIES.BLOCK_LIST}>
        <style.Content>
          <QuickSettingsCard
            test_id="fully-apps-management-disabled"
            title={t('components.component_support_message.component_fm_card_content.apps_management_title')}
            label={t('components.component_support_message.component_fm_card_content.apps_management_label')}
            active={personalPlayStoreManagement.active}
            onChange={() => handleChangePlayStoreManagement(!personalPlayStoreManagement.active, 'active')}
            noBoxShadow
            disabled={!canEditGroupPolicy}
          >
            <style.CardDescription>
              {t('components.component_support_message.component_fm_card_content.apps_management_description')}
            </style.CardDescription>
            <AdvancedFlag flags={[{ managementType: 'FM + WP', icon: mdiAndroid, version: '11.0', OS: 'Android' }]} />
          </QuickSettingsCard>
          <style.BottomContent>
            <style.ContentBox>
              <style.BoxItem>
                <EuiRadio
                  style={{ width: 25 }}
                  id="blockAppsList"
                  disabled={!personalPlayStoreManagement.active || !canEditGroupPolicy}
                  checked={personalPlayStoreManagement.personalPlayStoreMode === 'BLOCKLIST'}
                  onChange={() => handleChangePlayStoreManagement('BLOCKLIST', 'mode')}
                />
                <p className="title">Block List</p>
                <p className="description">{t('components.component_support_message.component_fm_card_content.apps_management_inline_block')}</p>
              </style.BoxItem>
              <style.BoxItem>
                <EuiRadio
                  style={{ width: 25 }}
                  id="allowAppsList"
                  disabled={!personalPlayStoreManagement.active || !canEditGroupPolicy}
                  checked={personalPlayStoreManagement.personalPlayStoreMode === 'ALLOWLIST'}
                  onChange={() => handleChangePlayStoreManagement('ALLOWLIST', 'mode')}
                />
                <p className="title">Allow List</p>
                <p className="description">{t('components.component_support_message.component_fm_card_content.apps_management_inline_allow')}</p>
              </style.BoxItem>
              <div className="identifierField">
                <style.Label>{t('components.component_support_message.component_fm_card_content.apps_management_identifier_input')}</style.Label>
                <div className="textField">
                  <EuiFieldText
                    disabled={!personalPlayStoreManagement.active || !canEditGroupPolicy}
                    compressed
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                  />
                  <EuiButton
                    disabled={!personalPlayStoreManagement.active || !canEditGroupPolicy}
                    className="addButton"
                    size="s"
                    onClick={() => {
                      addApp();
                    }}
                  >
                    {t('components.component_support_message.component_fm_card_content.apps_management_add_button')}
                  </EuiButton>
                </div>
              </div>
              <div>
                {selection.length > 0 && (
                  <EuiButton
                    disabled={!personalPlayStoreManagement.active || !canEditGroupPolicy}
                    className="removeButton"
                    color="danger"
                    size="s"
                    onClick={() => {
                      removeSelected();
                    }}
                  >
                    {t('components.component_support_message.component_fm_card_content.apps_management_remove_button')}
                  </EuiButton>
                )}
                {personalPlayStoreManagement.personalPlayStoreMode === 'ALLOWLIST' ? (
                  <style.Label>{t('components.component_support_message.component_fm_card_content.apps_management_warning_message')}</style.Label>
                ) : (
                  <style.Label>
                    {t('components.component_support_message.component_fm_card_content.apps_management_block_warning_message')}
                  </style.Label>
                )}
                <EuiInMemoryTable
                  itemId="id"
                  items={personalPlayStoreManagement.personalApplications ? list : []}
                  isSelectable={canEditGroupPolicy}
                  selection={selectionValue}
                  columns={cols}
                  pagination={pagination}
                  message={message}
                  contentEditable={canEditGroupPolicy}
                />
              </div>
            </style.ContentBox>
          </style.BottomContent>
        </style.Content>
      </ComponentResourceChecker>
    </style.Container>
  );
}
