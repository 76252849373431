import { EuiButtonGroup, EuiRadioGroup, EuiSwitch } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Content = styled.main`
  padding: 40px 60px 60px 50px;
  margin-left: 50px;
  background-color: ${ConstantColors.AUSTRIA};

  .bottom-border {
    padding-bottom: 5px;
    border-bottom: 1px solid #ededed;
  }

  .theme-color {
    color: ${({ theme }) => theme.main};
  }
`;

export const TopContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopLeft = styled.div`
  width: 45%;

  p {
    color: ${ConstantColors.ITALY};
  }

  div {
    margin: 15px 0;
  }

  h1 {
  }
`;

export const SafetyNetSwitch = styled(EuiSwitch)`
  .euiSwitch__body {
    background-color: ${({ theme }) => theme.main};
  }

  span {
    font-weight: 500;
  }
`;

export const TopRight = styled.div`
  p {
    font-weight: 700;
    margin-bottom: 15px;
  }
`;

export const GroupButton = styled(EuiButtonGroup)`
  .euiButtonGroup__buttons {
    border-radius: 0 !important;
  }

  .euiButton__content {
    padding: 0 !important;
  }

  .euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    color: #fff;
    background-color: ${({ theme }) => theme.main};
    border-color: ${({ theme }) => theme.main};
    font-weight: 700;
  }

  .euiButtonGroupButton {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.07px;
    height: 38px;
    padding: 0 20px;
    border-radius: 0 !important;
  }
`;

export const BottomContainer = styled.section`
  margin-top: 25px;
  position: relative;

  .vertical-line {
    border-bottom: 1px solid #ededed;
    width: 130px;
    position: absolute;
    transform: rotate(90deg);
    left: 34%;
    bottom: 65px;
    margin-left: 15px;
  }
`;

export const ManagementTitle = styled.section`
  display: grid;
  grid-template-columns: 25% 20% 60%;
  margin-top: 25px;

  .management-name {
    font-size: 15px;
    font-weight: 600;
  }

  .column-name {
    font-size: 13px;
    font-weight: 600;
  }

  .icon-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .wp-icon {
    width: 22px;
  }

  .fm-icon {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${ConstantColors.DJBOUTI};
    border-radius: 4px;

    svg {
      width: 13px;
    }
  }
`;

export const Options = styled.section`
  display: grid;
  grid-template-columns: 25% 20% 55%;
  padding-bottom: 10px;
  margin-top: 10px;

  p {
    margin-left: 5px;
  }

  .options-title {
    font-size: 15px;
    font-weight: 400;
    margin-left: 28px;
  }

  &:last-child {
    margin-top: 15px;
  }
`;

export const OptionsSwitch = styled(EuiSwitch)`
  align-items: center;

  .euiSwitch__body {
    width: 28px;
    height: 16px;
  }

  .euiSwitch__thumb {
    padding: 5.8px;
    top: 50%;
    left: 14.2px;
    border: none;
    transform: translateY(-50%);
  }

  &:hover {
    .euiSwitch__button:not(:disabled) .euiSwitch__thumb {
      transform: translateY(-50%) scale(1.05);
    }
  }

  .euiSwitch__button:disabled {
    .euiSwitch__body {
      background-color: #d3dae6 !important;
    }
  }

  .euiSwitch__button[aria-checked='false'] {
    .euiSwitch__thumb {
      left: 3px;
    }
  }

  .euiSwitch__track {
    display: none;
  }

  .euiSwitch__label {
    font-weight: 500;
    font-size: 13px;
  }
`;

export const OptionsRadios = styled(EuiRadioGroup)`
  display: grid;
  grid-template-columns: 30% 40% 30%;

  .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: ${({ theme }) => theme.main};
    background-color: ${({ theme }) => theme.main};
  }

  .euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
    border-color: #d3dae6;
    background-color: #d3dae6;
  }

  .euiRadioGroup__item {
    margin: 0;
  }
`;
