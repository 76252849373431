import { EuiCallOut } from '@elastic/eui';
import styled from 'styled-components';

export const Content = styled.main`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 8px;
  overflow-y: auto;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Subtitle = styled.p`
  font-size: 13px;
  color: #67758d;
  margin-bottom: 30px;
  text-align: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;

  .euiButton--primary.euiButton--fill,
  .euiButton--primary.euiButton--fill:focus,
  .euiButton--primary.euiButton--fill:hover {
    background: ${({ theme }) => theme.main};
    border: ${({ theme }) => theme.main};
    text-decoration: none;
  }

  .euiButton__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .euiButtonEmpty__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.08px;
    color: #30a8ff;
  }
`;

export const Notice = styled(EuiCallOut)`
  border: none;

  .euiCallOutHeader {
    align-items: center;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
  }
`;
