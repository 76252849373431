import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiButtonEmpty, EuiContextMenuItem, EuiContextMenuPanel, EuiInMemoryTable, EuiLoadingContent, EuiPopover } from '@elastic/eui';
import { useEffect, useMemo, useState } from 'react';
import * as S from './ContactsTableComponent.styles';
import Icon from '@mdi/react';
import { mdiDelete, mdiPencil } from '@mdi/js';
import { useTypedTranslation } from 'modules/core/hooks';
import type { Toast } from 'modules/core/components';
import { ComponentToast, TextEllipsisComponent } from 'modules/core/components';
import type { ContactsTableComponentProps } from './ContactsTableComponent.contracts';
import ConfirmDeleteModalComponent from '../ConfirmDeleteModal/ConfirmDeleteModalComponent';
import type { ContactsLists, List, ListIdentity } from 'modules/management/entities/contacts/ContactsList.entity';
import { ServicePulsusWebDAV } from 'services/ServicePulsusWebDAV/ServicePulsusWebDAV';
import type { GetContactsListBody } from 'services/ServicePulsusWebDAV/ServicePulsusWebDAV.contracts';
import EditModalComponent from '../EditModal/EditModalComponent';
import UploadModalComponent from '../UploadModal/UploadModalComponent';
import { use } from 'i18next';
import { useUserContext } from 'modules/core/contexts/user';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';

export const ContactsTableComponent = ({ isUploadModalOpen, closeUploadModal }: ContactsTableComponentProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [rowSize, setRowSize] = useState(25);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [isRowCountPopoverOpen, setIsRowCountPopoverOpen] = useState(false);
  const [contactListId, setContactListId] = useState('');
  const [contactListToEdit, setContactListToEdit] = useState<List>();
  const [contactsList, setContactsList] = useState<ContactsLists['list']>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [file, setFile] = useState<File>();
  const { permissions } = useUserContext();

  const { t } = useTypedTranslation<'contacts'>('contacts');

  const serviceWebDAV = useMemo(() => new ServicePulsusWebDAV(), []);

  const getContactsList = async () => {
    try {
      const params: GetContactsListBody = {
        page: activePage + 1,
        items_per_page: itemsPerPage,
      };

      const contactsLocal = await serviceWebDAV.getContactsList(params);
      const numberOfPages = Math.ceil(contactsLocal.totalItems / itemsPerPage);

      setPageCount(numberOfPages);
      if (!contactsLocal.list.length) {
        setErrorMessage(t('table.none_found_message'));
      } else {
        setErrorMessage('');
        setContactsList(contactsLocal.list);
      }
    } catch {
      setErrorMessage(t('table.fail_message'));
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('requests.list_error.title'),
          text: t('requests.list_error.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: EuiBasicTableColumn<List>[] = [
    {
      field: 'identity',
      name: t('table.name'),
      render: (identity: ListIdentity, list) => renderNameColumn(identity, list),
    },
    {
      field: 'description',
      name: t('table.description'),
      render: (description: string) => renderDescriptionColumn(description),
    },
    {
      field: 'quantity',
      name: t('table.quantity'),
      align: 'center',
    },
    {
      field: 'identity',
      name: 'Download',
      align: 'center',
      render: (identity: ListIdentity, list) => renderDownloadColumn(identity.id, list),
    },
    {
      field: 'status',
      name: t(`table.status`),
      align: 'center',
      render: (status: string) => renderStatusColumn(status),
    },
    {
      field: 'identity',
      name: t('table.actions'),
      align: 'center',
      render: (identity: ListIdentity, list) => renderActionColumn(identity.id, list),
    },
  ];

  const getIconType = (size: number) => {
    return size === rowSize ? 'check' : 'empty';
  };

  const items = [
    <EuiContextMenuItem
      key="25 rows"
      icon={getIconType(25)}
      onClick={() => {
        setIsRowCountPopoverOpen(false);
        handleRowSize(25);
      }}
    >
      {`25 ${t('table.pagination.items')}`}
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="50 rows"
      icon={getIconType(50)}
      onClick={() => {
        setIsRowCountPopoverOpen(false);
        handleRowSize(50);
      }}
    >
      {`50 ${t('table.pagination.items')}`}
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="100 rows"
      icon={getIconType(100)}
      onClick={() => {
        setIsRowCountPopoverOpen(false);
        handleRowSize(100);
      }}
    >
      {`100 ${t('table.pagination.items')}`}
    </EuiContextMenuItem>,
  ];

  const handleRowSize = (size: number) => {
    setRowSize(size);
    setItemsPerPage(size);
  };

  const button = (
    <EuiButtonEmpty size="s" color="text" iconType="arrowDown" iconSide="right" onClick={() => setIsRowCountPopoverOpen(!isRowCountPopoverOpen)}>
      {t('table.pagination.text')} {rowSize}
    </EuiButtonEmpty>
  );

  const closeEditModal = (reloadList: boolean, toastWarning?: Toast[]) => {
    setContactListToEdit(undefined);
    setFile(undefined);
    setContactListId('');
    closeUploadModal();

    if (toastWarning) {
      setToast((prev) => [...prev, ...toastWarning]);
    }

    if (reloadList) {
      getContactsList();
    }
  };

  const closeDeleteModal = () => {
    setContactListId('');
    getContactsList();
  };

  const downloadFile = async (id: string, list: List) => {
    try {
      const fileBlob = await serviceWebDAV.downloadFile(id);

      const fileName = list.fileName;

      const url = window.URL.createObjectURL(fileBlob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      link.parentNode?.removeChild(link);
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('requests.download_error.title'),
          text: t('requests.download_error.text'),
          id: '1',
        },
      ]);
    }
  };

  const renderNameColumn = (identity: ListIdentity, list: List) => {
    const { name } = identity;

    return (
      <S.NameColumn>
        <TextEllipsisComponent className="euiTableCellContent__text" title={name}>
          {name}
        </TextEllipsisComponent>
        <ConditionalSlotComponent renderIf={permissions?.contacts}>
          <button className="edit-icon-button link" type="button" onClick={() => setContactListToEdit(list)}>
            <Icon path={mdiPencil} size={0.8} color="#67758d" />
          </button>
        </ConditionalSlotComponent>
      </S.NameColumn>
    );
  };

  const renderDescriptionColumn = (description: string) => {
    return (
      <TextEllipsisComponent className="euiTableCellContent__text" title={description}>
        {description}
      </TextEllipsisComponent>
    );
  };

  const renderStatusColumn = (status: string) => {
    const isProcessing = status == 'processing';
    return (
      <S.StatusColumn>
        <S.Status isProcessing={isProcessing}>
          <TextEllipsisComponent className="euiTableCellContent__text" title={status}>
            {isProcessing ? t('table.processing') : t('table.done')}
          </TextEllipsisComponent>
        </S.Status>
      </S.StatusColumn>
    );
  };

  const renderDownloadColumn = (id: string, list: List) => {
    return (
      <EuiButtonEmpty onClick={() => downloadFile(id, list)} iconType={'download'} className="link">
        {t('table.download')}
      </EuiButtonEmpty>
    );
  };

  const renderActionColumn = (id: string, list: List) => {
    return (
      <ConditionalSlotComponent renderIf={list.canEdit}>
        <EuiButtonEmpty onClick={() => setContactListId(id)} title={t('table.action_delete')}>
          <Icon color={'#F44336'} path={mdiDelete} size={1} />
        </EuiButtonEmpty>
      </ConditionalSlotComponent>
    );
  };

  useEffect(() => {
    getContactsList();
  }, [activePage, itemsPerPage]);

  return (
    <S.Content>
      <EuiInMemoryTable
        error={errorMessage}
        itemId="contactId"
        columns={columns}
        message={<EuiLoadingContent lines={10} />}
        items={isLoading ? [] : contactsList}
        isSelectable
        loading={isLoading}
      />
      <S.PaginationContainer>
        <EuiPopover
          panelStyle={{ fontSize: '12px' }}
          title={t('table.pagination.text')}
          button={button}
          isOpen={isRowCountPopoverOpen}
          closePopover={() => setIsRowCountPopoverOpen(false)}
          panelPaddingSize="none"
        >
          <EuiContextMenuPanel items={items} />
        </EuiPopover>
        <S.Pagination
          aria-label="Pagination Navigation"
          pageCount={pageCount}
          activePage={activePage}
          onPageClick={(page: number) => setActivePage(page)}
        />
      </S.PaginationContainer>
      <EditModalComponent
        close={(reloadList, toastWarning) => closeEditModal(!!reloadList, toastWarning)}
        contactListToEdit={contactListToEdit}
        file={file}
      />
      <UploadModalComponent close={() => closeUploadModal()} open={isUploadModalOpen} openEditModal={(fileLocal: File) => setFile(fileLocal)} />
      <ConfirmDeleteModalComponent close={() => closeDeleteModal()} contactListId={contactListId} />
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </S.Content>
  );
};
