import { FullyCardContent } from 'components/fm-card-content/ComponentFmCardContent';
import { IconMobileDevice, IconWorkProfile } from 'icons';
import type { FullyManagedCardGroupProps } from './component-fm-card-settings.contracts';
import * as style from './component-fm-card-settings.style';
import { ResourceContextProvider } from '../../modules/core/contexts';
import { UserProvider } from 'modules/core/contexts/user';

export function FullyCardSettings(props: FullyManagedCardGroupProps) {
  const {
    cameraDisabled,
    screenCaptureDisabled,
    maxDaysWithWorkOff,
    personalPlayStoreManagement,
    handleChangePlayStoreManagement,
    onChangeBoolean,
    onChangeNumber,
  } = props;
  return (
    <ResourceContextProvider>
      <style.Container data-testid="fully-component-card">
        <style.Header>
          <style.CustomIcon data-testid="fully-component-icons">
            <IconMobileDevice width="30px" heigth="18px" color="white" />
            <IconWorkProfile className="absoluteIcon" width={13} height={12} fill="#1483FF" />
          </style.CustomIcon>
          <style.Title data-testid="fully-component-title">Fully Managed with a Work Profile</style.Title>
        </style.Header>
        <UserProvider>
          <FullyCardContent
            cameraDisabled={cameraDisabled}
            screenCaptureDisabled={screenCaptureDisabled}
            maxDaysWithWorkOff={maxDaysWithWorkOff}
            personalPlayStoreManagement={personalPlayStoreManagement}
            onChangeBoolean={(value, key) => onChangeBoolean(value, key)}
            onChangeNumber={(value, key) => onChangeNumber(value, key)}
            handleChangePlayStoreManagement={(value, key) => handleChangePlayStoreManagement(value, key)}
          />
        </UserProvider>
      </style.Container>
    </ResourceContextProvider>
  );
}
