import { useQuery } from 'hooks/use-query';
import { PageChromeOSIntegration } from 'modules/chromeOS/pages/integration/page-chromeOS-integration';
import { ConstantLanguages } from 'utilities';
import i18next from 'i18next';
import { UserProvider } from 'modules/core/contexts/user';

export function EmbeddedChromeOSIntegration() {
  const query = useQuery();
  const language = query.get('language') || ConstantLanguages.PT_BR;
  i18next.changeLanguage(language);

  return (
    <UserProvider>
      <PageChromeOSIntegration embedded />
    </UserProvider>
  );
}
