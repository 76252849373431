import { DialogComponent } from 'components';
import type { EditModalComponentProps } from './EditModalComponent.contracts';
import * as S from './EditModalComponent.styles';
import type { EuiComboBoxOptionOption } from '@elastic/eui';
import { EuiButton, EuiButtonEmpty, EuiComboBox, EuiFieldText, EuiFilePicker, EuiFormRow } from '@elastic/eui';
import { useTypedTranslation } from 'modules/core/hooks';
import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { Group } from 'entities/group';
import { ServicePulsusLoki, ServicePulsusWeb } from 'services';
import { ServicePulsusWebDAV } from 'services/ServicePulsusWebDAV/ServicePulsusWebDAV';
import type { AddressBook } from 'services/ServicePulsusWebDAV/ServicePulsusWebDAV.contracts';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { AxiosError } from 'axios';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';
import { HelperAuthentication } from 'helpers';

const EditModalComponent = ({ contactListToEdit, close, file }: EditModalComponentProps) => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [description, setDescription] = useState('');
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<EuiComboBoxOptionOption<Group>[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [toast, setToast] = useState<Toast[]>([]);

  const { t } = useTypedTranslation<'contacts.edit_modal'>('contacts.edit_modal');

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const serviceWebDAV = useMemo(() => new ServicePulsusWebDAV(), []);
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const getGroups = async () => {
    setIsLoadingGroups(true);

    try {
      const response = await serviceLoki.getGroups();
      if (contactListToEdit) await getAssociatedGroupsById(response);
      applyGroupRestrictions(response);
    } catch {
      setGroups([]);
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request_groups_error.title'),
          text: t('request_groups_error.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoadingGroups(false);
    }

    function applyGroupRestrictions(response: Group[]) {
      const token = HelperAuthentication.token();
      const payload = HelperAuthentication.decodeJwtFrom(token);
      if (HelperAuthentication.decodedAdminIsRestricted() === true) {
        const restrictedGroupsIds: number[] = payload?.restricted_group_ids as number[];
        if (Array.isArray(restrictedGroupsIds) && restrictedGroupsIds.length > 0) {
          response.forEach((item) => {
            if (!restrictedGroupsIds.includes(item.id)) {
              item.className = 'disabled';
              item.disabled = true;
            }
          });
        } else {
          response.forEach((item) => {
            item.className = 'disabled';
            item.disabled = true;
          });
        }
      }
      setGroups(response);
    }
  };

  const getAssociatedGroupsById = async (groupsLocal: Group[]) => {
    try {
      if (contactListToEdit && !!groupsLocal.length) {
        const associatedGroupsIds = await serviceWebDAV.getAssociatedGroupsOfContactList(contactListToEdit?.identity.id);

        const associatedGroups = groupsLocal.filter((item) => associatedGroupsIds.includes(item.id));

        setSelectedGroups(associatedGroups);
      }
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request_associated_groups_error.title'),
          text: t('request_associated_groups_error.text'),
          id: '1',
        },
      ]);
    }
  };

  const onSubmit = async () => {
    if (!name) {
      setNameError(t('empty_name_error'));
    }

    if (!name) {
      return;
    } else {
      await createOrEditList();
    }
  };

  const sendContactsAppInstall = async () => {
    const groupIds = selectedGroups.map((item) => Number(item.id));

    try {
      await servicePulsusWeb.sendContactsAppInstall(groupIds);
    } catch {
      const toastFail: Toast[] = [
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request_send_app_install_error.title'),
          text: t('request_send_app_install_error.text'),
          id: '1',
        },
      ];
      close(false, toastFail);
    }
  };

  const createOrEditList = async () => {
    setIsLoadingSave(true);

    try {
      const addressBook: AddressBook = {
        group_ids: selectedGroups.map((item) => Number(item.id)),
        name: name,
        description: description,
      };

      if (file) {
        await serviceWebDAV.createContactList(file, addressBook);

        const toastWarning: Toast[] = [
          {
            color: 'success',
            iconType: 'faceHappy',
            title: t('request_success.title'),
            text: t('request_success.text'),
            id: '1',
          },
        ];

        close(true, toastWarning);
      }

      if (contactListToEdit) {
        const contactsListId = contactListToEdit.identity.id;
        await serviceWebDAV.editContactList(contactsListId, addressBook);
        close(true);
      }

      if (selectedGroups.length) {
        await sendContactsAppInstall();
      }
    } catch (error) {
      let textError = t('request_save_error.text_default');

      if (error instanceof AxiosError) {
        if (error.response?.data.detail === 'Address book with that name already exists.') {
          textError = t('request_save_error.text_repeated_name');
        }
      }

      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request_save_error.title'),
          text: textError,
          id: '1',
        },
      ]);
    } finally {
      setIsLoadingSave(false);
    }
  };

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setNameError('');
  };

  const onChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    setNameError('');

    if (contactListToEdit || file) {
      getGroups();
    }

    if (contactListToEdit) {
      setName(contactListToEdit.identity.name);
      setDescription(contactListToEdit.description);
    } else {
      setName('');
      setDescription('');
      setSelectedGroups([]);
    }
  }, [contactListToEdit, file]);

  const renderFooter = () => {
    return (
      <S.ModalFooter>
        <EuiButtonEmpty onClick={() => close()}>{t('cancel')}</EuiButtonEmpty>
        <EuiButton isLoading={isLoadingSave} onClick={() => onSubmit()} fill>
          {t('save')}
        </EuiButton>
      </S.ModalFooter>
    );
  };

  return (
    <DialogComponent
      width="612px"
      height="570px"
      maxHeight="100vh"
      title={contactListToEdit ? t('title_edit') : t('title')}
      footer={renderFooter()}
      onClose={close}
      open={!!contactListToEdit || !!file}
      closeOnClickOutside
    >
      <S.Content>
        <S.Subtitle>{t('subtitle')}</S.Subtitle>
        <EuiFormRow fullWidth label={t('file')}>
          <EuiFilePicker
            disabled
            initialPromptText={file ? file.name : contactListToEdit?.fileName}
            display="default"
            aria-label="upload file"
            fullWidth
          />
        </EuiFormRow>
        <ConditionalSlotComponent renderIf={!!file || !!contactListToEdit?.canEdit}>
          <EuiFormRow isInvalid={!!nameError} error={nameError} fullWidth label={t('name')}>
            <EuiFieldText
              isInvalid={!!nameError}
              fullWidth
              placeholder={t('name_placeholder')}
              value={name}
              onChange={(event) => onChangeName(event)}
            />
          </EuiFormRow>
        </ConditionalSlotComponent>
        <ConditionalSlotComponent renderIf={!!file || !!contactListToEdit?.canEdit}>
          <EuiFormRow fullWidth label={t('description')}>
            <EuiFieldText
              fullWidth
              placeholder={t('description_placeholder')}
              value={description}
              onChange={(event) => {
                const value = event.target.value;
                if (!value.includes('\\') && !value.includes('"')) {
                  onChangeDescription(event);
                }
              }}
            />
          </EuiFormRow>
        </ConditionalSlotComponent>
        <EuiFormRow fullWidth label={t('groups')}>
          <EuiComboBox
            fullWidth
            isLoading={isLoadingGroups}
            aria-label="Groups select"
            placeholder={t('groups_placeholder')}
            options={groups}
            selectedOptions={selectedGroups}
            onChange={(item) => setSelectedGroups(item)}
            isClearable
            className="euiComboBox"
          />
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <S.Notice title={t('notice')} iconType="iInCircle" />
        </EuiFormRow>
      </S.Content>
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </DialogComponent>
  );
};

export default EditModalComponent;
