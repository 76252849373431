import { ComponentBreadcrumb, ComponentSteps, ComponentTextField, DialogComponent } from 'components';
import { useQuery } from 'hooks/use-query';
import {
  IconPulsusLogo,
  BannerChromeOS,
  BannerChromeOSSteps,
  StampAdminConsole,
  BannerIntegrationModal,
  BannerChromeIntegrationErrorModal,
} from 'icons';
import { EuiButton, EuiTextColor } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import scaredGirl from 'assets/images/chrome-poupup.png';
import type { Toast } from 'modules/core/components';
import { ComponentLoader, ComponentToast } from 'modules/core/components';
import { ChromeEnrollInformation } from 'components/application-header/enroll-popups/chromeOS/chromeOS-information-topic';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { ChromeBreadcrumbs } from '../chromeBreadcrumbs';
import * as S from './page-chromeOS-integration.style';
import type { PageChromeOSIntegrationProps, GoogleResponseData } from './page-chromeOS-integration.contracts';
import { useUserContext } from 'modules/core/contexts/user';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';

export function PageChromeOSIntegration({ embedded }: PageChromeOSIntegrationProps) {
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false);
  const [informationModalOpen, setInformationModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState<'unauthorized' | 'unlicensed' | null>(null);
  const [infoMode, setInfoMode] = useState<'info' | 'sync'>('info');
  const [accountData, setAccountData] = useState<GoogleResponseData | null>();
  const [errorEmail, setErrorEmail] = useState<string>('');
  const query = useQuery();
  const { t } = useTranslation();
  const [toast, setToast] = useState<Toast[]>([]);
  const [enrollModalOpen, setEnrollModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkedVerify, setLinkedVerify] = useState(false);
  const { permissions } = useUserContext();

  const LokiServices = useMemo(() => new ServicePulsusLoki(), []);

  const steps = [
    {
      title: t('pages.pulsus_chrome_os_integration.step_one_title'),
      children: (
        <S.ColoredText>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.step_one_prefix')}</EuiTextColor>
          <EuiTextColor color="secondary">
            <a href="https://support.google.com/chrome/a/topic/7613713" target="_blank" rel="noreferrer">
              {` ${t('pages.pulsus_chrome_os_integration.step_one_sufix')}`}
            </a>
          </EuiTextColor>
        </S.ColoredText>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.step_two_title'),
      children: (
        <S.ColoredText>
          <EuiTextColor>{`${t('pages.pulsus_chrome_os_integration.step_two_prefix')}`}</EuiTextColor>
          <EuiTextColor color="secondary">
            <a href="https://support.google.com/chrome/a/answer/7532316?hl=en#zippy=" target="_blank" rel="noreferrer">
              {t('pages.pulsus_chrome_os_integration.step_two_sufix')}
            </a>
          </EuiTextColor>
        </S.ColoredText>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.step_three_title'),
      children: (
        <S.ColoredText>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.step_three_prefix')}</EuiTextColor>
          <EuiTextColor color="secondary">
            <a href="https://support.google.com/chrome/a/answer/1360642?hl=en" target="_blank" rel="noreferrer">
              {` ${t('pages.pulsus_chrome_os_integration.step_three_sufix')}`}
            </a>
          </EuiTextColor>
        </S.ColoredText>
      ),
    },
  ];

  const errorSteps = [
    {
      title: t('pages.pulsus_chrome_os_integration.error_modal_step_1_title'),
      children: (
        <S.ErrorModalDescription>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_step_1_text_1')}</EuiTextColor>
          <EuiTextColor className="bold">{errorEmail || 'not_received'}</EuiTextColor>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_step_1_text_2')}</EuiTextColor>
          <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_step_1_text_3')}</EuiTextColor>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_step_1_text_4')}</EuiTextColor>
          <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_step_1_text_5')}</EuiTextColor>
        </S.ErrorModalDescription>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.error_modal_step_2_title'),
      children: (
        <S.ErrorModalDescription>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_step_2_text_1')}</EuiTextColor>
          <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_step_2_text_2')}</EuiTextColor>
        </S.ErrorModalDescription>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.error_modal_step_3_title'),
      children: (
        <S.ErrorModalDescription>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_step_3_text_1')}</EuiTextColor>
          <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_step_3_text_2')}</EuiTextColor>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_step_3_text_3')}</EuiTextColor>
          <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_step_3_text_4')}</EuiTextColor>
        </S.ErrorModalDescription>
      ),
    },
  ];

  async function callGoogle() {
    if (embedded) {
      const url = await LokiServices.getChromeIntegrationUrl(`${document.referrer}chromeos/integration`);
      window.parent.location.href = url;
    } else {
      const url = await LokiServices.getChromeIntegrationUrl(window.location.origin + window.location.pathname);
      window.open(url, '_self');
    }
  }

  function checkIsLinked() {
    LokiServices.checkChromeOSIntegration().then((account) => {
      if (account) {
        setAccountData(account);
      } else if (query.get('state') && typeof query.get('state') === 'string') {
        sendGoogleReturnBody(query.get('state') as string);
      }
      setLinkedVerify(true);
    });
  }

  function sendGoogleReturnBody(state: string) {
    setLoading(true);
    LokiServices.postChromeOSIntegration(window.location.href, state).then((response) => {
      setLoading(false);
      if (response) {
        switch (response.status) {
          case 201:
            setLinkModalOpen(true);
            setAccountData((prevState) => ({ ...prevState, ...response.data }));
            break;
          case 401:
            setErrorEmail(response.data.detail.email);
            openErrorModal(response.data.detail.integration);
            break;
          case 403:
            setErrorEmail(response.data.detail.email);
            openErrorModal(response.data.detail.integration);
            break;
          default:
            break;
        }
      }
    });
  }

  function openErrorModal(type: 'unauthorized' | 'unlicensed') {
    setErrorModalOpen(type);
  }

  function unlinkAccount() {
    setUnlinkModalOpen(false);
    LokiServices.deleteChromeOSIntegration().then((data) => {
      if (data.status === 200) {
        setAccountData(null);
      }
      setToast([
        {
          title:
            data.status === 200
              ? t('pages.pulsus_chrome_os_integration.account_unlink_success')
              : t('pages.pulsus_chrome_os_integration.account_unlink_error'),
          color: data.status === 200 ? 'success' : 'danger',
          iconType: data.status === 200 ? 'faceHappy' : 'faceSad',
          id: '1',
          text: '',
        },
      ]);
    });
  }

  function onOpenInformationModal(type: 'info' | 'sync') {
    setInfoMode(type);
    setInformationModalOpen(true);
  }

  useEffect(() => {
    checkIsLinked();
  }, []);

  return (
    <>
      <ComponentLoader isLoading={loading} overflow>
        {!embedded && (
          <S.BreadcrumbBox data-testid="chromeOS-integration-breadcrumb">
            <ComponentBreadcrumb items={ChromeBreadcrumbs.PULSUS_CHROME_OS_INTEGRATION.breadcrumbs} />
          </S.BreadcrumbBox>
        )}
        <S.ContentGroup data-testid="chromeOS-integration-content-group">
          <S.InfoBox>
            <S.LogoBox data-testid="chromeOS-integration-header-icons">
              <IconPulsusLogo color="#525252" />
              <S.Title>ChromeOS</S.Title>
            </S.LogoBox>
            <ComponentLoader height="250px" isLoading={!linkedVerify}>
              <S.ButtonsBox data-testid="chromeOS-integration-button-box">
                {!accountData ? (
                  <>
                    <S.Description data-testid="chromeOS-integration-description">
                      <EuiTextColor>{t('pages.pulsus_chrome_os_integration.description_first')}</EuiTextColor>
                      <EuiTextColor> </EuiTextColor>
                      <EuiTextColor color="secondary">{t('pages.pulsus_chrome_os_integration.description_second')}</EuiTextColor>
                      <EuiTextColor> </EuiTextColor>
                      <EuiTextColor>{t('pages.pulsus_chrome_os_integration.description_third')}</EuiTextColor>
                      <EuiTextColor> </EuiTextColor>
                      <EuiTextColor color="secondary">{t('pages.pulsus_chrome_os_integration.description_fourth')}</EuiTextColor>
                    </S.Description>
                    <S.SyncBox>
                      <ConditionalSlotComponent renderIf={permissions?.chromeos_integrations}>
                        <S.SyncButton
                          fill
                          iconType="plusInCircle"
                          data-testid="chromeOS-integration-button-sync"
                          onClick={() => {
                            onOpenInformationModal('sync');
                          }}
                        >
                          {t('pages.pulsus_chrome_os_integration.sync_button')}
                        </S.SyncButton>
                      </ConditionalSlotComponent>
                      <S.LinkButton iconType="iInCircle" onClick={() => onOpenInformationModal('info')}>
                        {t('pages.pulsus_chrome_os_integration.information_button')}
                      </S.LinkButton>
                    </S.SyncBox>
                  </>
                ) : (
                  <S.UnsyncGroup>
                    <S.UnsyncGroupTitle>{t('pages.pulsus_chrome_os_integration.unsync_title')}</S.UnsyncGroupTitle>
                    <S.UnsyncBox>
                      <S.UnsyncHeader>
                        <S.UnsyncLabel>{t('pages.pulsus_chrome_os_integration.unsync_box_label')}</S.UnsyncLabel>
                        <S.UnsyncTitle>ChromeOS</S.UnsyncTitle>
                      </S.UnsyncHeader>
                      <S.UnsyncBody>
                        <S.AccountInormations>
                          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.unsync_box_user')}</EuiTextColor>
                          <EuiTextColor color="secondary">{` ${accountData?.name}`}</EuiTextColor>
                        </S.AccountInormations>
                        <S.AccountInormations>
                          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.unsync_box_email')}</EuiTextColor>
                          <EuiTextColor color="secondary">{` ${accountData?.email}`}</EuiTextColor>
                        </S.AccountInormations>
                      </S.UnsyncBody>
                      <S.AccountInformationsActions>
                        <ConditionalSlotComponent renderIf={permissions?.chromeos_integrations}>
                          <S.UnsyncButton fill data-testid="chromeOS-integration-button-unsync" onClick={() => setUnlinkModalOpen(true)}>
                            {t('pages.pulsus_chrome_os_integration.unsync_box_button')}
                          </S.UnsyncButton>
                          <S.LinkButton iconType="iInCircle" onClick={() => setEnrollModalOpen(true)}>
                            {t('pages.pulsus_chrome_os_integration.unsync_box_add_devices')}
                          </S.LinkButton>
                        </ConditionalSlotComponent>
                      </S.AccountInformationsActions>
                    </S.UnsyncBox>
                  </S.UnsyncGroup>
                )}
                <S.LinkButton style={{ display: 'none' }} onClick={() => null}>
                  {t('pages.pulsus_chrome_os_integration.link_button')}
                </S.LinkButton>
              </S.ButtonsBox>
            </ComponentLoader>
          </S.InfoBox>
          <S.BannerBox data-testid="chromeOS-integration-header-banner-box">
            <BannerChromeOS test_id="chromeOS-integration-header-banner" />
          </S.BannerBox>
        </S.ContentGroup>
      </ComponentLoader>
      <DialogComponent
        open={linkModalOpen}
        padding="40px"
        maxWidth="950px"
        maxHeight="620px"
        closeOnClickOutside
        onClose={() => {
          setLinkModalOpen(false);
        }}
      >
        <S.ModalContent>
          <S.ModalTitle>{t('pages.pulsus_chrome_os_integration.modal_title')}</S.ModalTitle>
          <div style={{ display: 'flex' }}>
            <S.LeftContent>
              <div>
                <StampAdminConsole />
              </div>
              <S.Fields>
                <ComponentTextField label={t('pages.pulsus_chrome_os_integration.modal_field_username')} disabled value={accountData?.name || ''} />
                <ComponentTextField label={t('pages.pulsus_chrome_os_integration.modal_field_email')} disabled value={accountData?.email || ''} />
              </S.Fields>
              <S.ModalWarningText>
                <EuiTextColor>{t('pages.pulsus_chrome_os_integration.modal_warning_prefix')}</EuiTextColor>
                <EuiTextColor> </EuiTextColor>
                <EuiTextColor color="secondary">{t('pages.pulsus_chrome_os_integration.modal_warning_sufix')}</EuiTextColor>
              </S.ModalWarningText>
              <S.ButtonBox>
                <EuiButton
                  fill
                  onClick={() => {
                    setLinkModalOpen(false);
                  }}
                >
                  {t('pages.pulsus_chrome_os_integration.modal_finish_button')}
                </EuiButton>
              </S.ButtonBox>
            </S.LeftContent>
            <S.RightContent>
              <BannerIntegrationModal width={500} />
            </S.RightContent>
          </div>
        </S.ModalContent>
      </DialogComponent>
      <DialogComponent open={unlinkModalOpen} maxWidth="950px" closeOnClickOutside onClose={() => setUnlinkModalOpen(false)}>
        <S.UnlinkModalContent>
          <img src={scaredGirl} alt="A scared girl" />
          <S.ModalRightSide>
            <S.UnlinkModalTitle>{t('pages.pulsus_chrome_os_integration.unlink_modal_title')}</S.UnlinkModalTitle>
            <S.UnlinkModalText>
              {`${t('pages.pulsus_chrome_os_integration.unlink_modal_text_first')} `}
              <b>{t('pages.pulsus_chrome_os_integration.unlink_modal_text_first_bold')}</b>
            </S.UnlinkModalText>
            <S.UnlinkModalText>{t('pages.pulsus_chrome_os_integration.unlink_modal_text_second')}</S.UnlinkModalText>
            <S.UnlinkModalText>
              {t('pages.pulsus_chrome_os_integration.unlink_modal_text_third')}
              <a href="https://support.google.com/chrome/a/answer/7532316?hl=en#zippy=" target="_blank" rel="noreferrer">
                {` ${t('pages.pulsus_chrome_os_integration.unlink_modal_link')}`}
              </a>
            </S.UnlinkModalText>
            <div style={{ display: 'flex', gap: 20, justifyContent: 'flex-end' }}>
              <S.LinkButton onClick={() => setUnlinkModalOpen(false)}>
                {t('pages.pulsus_chrome_os_integration.information_modal_cancel')}
              </S.LinkButton>
              <S.UnlinkButton
                fill
                onClick={() => {
                  unlinkAccount();
                }}
              >
                {t('pages.pulsus_chrome_os_integration.unlink_modal_button')}
              </S.UnlinkButton>
            </div>
          </S.ModalRightSide>
        </S.UnlinkModalContent>
      </DialogComponent>
      <DialogComponent
        padding="40px"
        maxWidth="950px"
        maxHeight="620px"
        open={informationModalOpen}
        closeOnClickOutside
        onClose={() => setInformationModalOpen(false)}
      >
        <S.InformationModalConatent>
          <S.SubContent data-testid="chromeOS-integration-sub-content">
            <S.LeftSubContent data-testid="chromeOS-integration-sub-left">
              <S.SubTitle>{t('pages.pulsus_chrome_os_integration.subtitle')}</S.SubTitle>
              <BannerChromeOSSteps test_id="chromeOS-integration-sub-banner" width="300px" />
            </S.LeftSubContent>
            <S.RightSubContent data-testid="chromeOS-integration-sub-right">
              <ComponentSteps id="chromeOS-integration-sub-steps" steps={steps} />
            </S.RightSubContent>
          </S.SubContent>
          <S.InformationModalActions>
            {infoMode === 'info' ? (
              <S.LinkButton onClick={() => setInformationModalOpen(false)}>
                {t('pages.pulsus_chrome_os_integration.information_modal_button')}
              </S.LinkButton>
            ) : (
              <>
                <S.LinkButton onClick={() => setInformationModalOpen(false)}>
                  {t('pages.pulsus_chrome_os_integration.information_modal_cancel')}
                </S.LinkButton>
                <S.ContinueButton
                  onClick={() => {
                    callGoogle();
                  }}
                >
                  {t('pages.pulsus_chrome_os_integration.information_modal_continue')}
                </S.ContinueButton>
              </>
            )}
          </S.InformationModalActions>
        </S.InformationModalConatent>
      </DialogComponent>
      <DialogComponent
        open={!!errorModalOpen}
        padding="40px"
        maxWidth="950px"
        maxHeight="620px"
        closeOnClickOutside
        onClose={() => setErrorModalOpen(null)}
      >
        <S.ErrorModalContainer>
          <S.ErrorModalTitle>{t('pages.pulsus_chrome_os_integration.error_modal_title')}</S.ErrorModalTitle>
          <S.ColoredText>
            {errorModalOpen !== 'unauthorized' ? (
              <S.ErrorModalDescription className="center">
                <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_description_1')}</EuiTextColor>
                <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_description_2')}</EuiTextColor>
                <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_description_3')}</EuiTextColor>
                <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_description_4')}</EuiTextColor>
              </S.ErrorModalDescription>
            ) : (
              <S.ErrorModalDescription className="center">
                <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_description_5')}</EuiTextColor>
                <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_description_6')}</EuiTextColor>
                <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_description_7')}</EuiTextColor>
                <EuiTextColor>{t('pages.pulsus_chrome_os_integration.error_modal_description_8')}</EuiTextColor>
                <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.error_modal_description_9')}</EuiTextColor>
              </S.ErrorModalDescription>
            )}
          </S.ColoredText>
          <S.ErrorModalBody>
            <S.ErrorModalLeft>
              <BannerChromeIntegrationErrorModal width={350} />
            </S.ErrorModalLeft>
            <S.ErrorModalRight>
              <ComponentSteps steps={errorSteps} />
            </S.ErrorModalRight>
          </S.ErrorModalBody>
        </S.ErrorModalContainer>
      </DialogComponent>
      <DialogComponent
        height="100%"
        width="100%"
        maxHeight="600px"
        maxWidth="1000px"
        zoom={0.9}
        closeOnClickOutside
        open={enrollModalOpen}
        onClose={() => setEnrollModalOpen(false)}
      >
        <S.AddDeviceModalContainer>
          <ChromeEnrollInformation onCancel={() => setEnrollModalOpen(false)} />
        </S.AddDeviceModalContainer>
      </DialogComponent>

      <ComponentToast toasts={toast} toastLifeTimeMs={5000} side="right" dismissToast={() => setToast([])} />
    </>
  );
}
