import React from 'react';
import { EuiSwitch } from '@elastic/eui';
import { random } from 'lodash';
import * as style from './component-quick-setting-card.style';
import type { QuickSettingsCardProps } from './component-quick-setting-card.contracts';
import { Flags } from '../flags/component-flags';

export const QuickSettingsCard = (props: QuickSettingsCardProps) => {
  const { title, label, active, flags, onChange, children, noSwitch, isContentOnly, bottomContent, noBottomPadding, noBoxShadow, test_id, disabled } =
    props;

  const renderCardContent = () => (
    <style.Card key={random()} className={`${noBottomPadding && 'noBottomPadding'} ${noBoxShadow && 'noBoxShadow'}`} title={title} textAlign="left">
      <style.CardContent>
        {!noSwitch && (
          <style.SwitchContainer>
            <EuiSwitch data-testid={test_id} label={label} checked={active} disabled={disabled} onChange={() => onChange()} />
          </style.SwitchContainer>
        )}
        <style.CardDescription>{children}</style.CardDescription>
        <Flags flags={flags} />
        {bottomContent && <style.BottomBox>{bottomContent}</style.BottomBox>}
      </style.CardContent>
    </style.Card>
  );

  return isContentOnly ? <>{renderCardContent()}</> : <style.CardContainer className="item">{renderCardContent()}</style.CardContainer>;
};
