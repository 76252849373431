export const ConstantServicesEndpoints = {
  PULSUS_WEB: {
    LOGIN: '/login',
    RECOVER_PASSWORD: '/password_recovery/forgot',
    ANDROID_SETTINGS: '/android-settings',
    CONNECTIVITY: '/connectivity',
    CERTIFICATES: '/certificates',
    GROUP_POLICIES: '/group_policies',
    APPLICATIONS: '/applications',
    CERTIFICATES_EDIT_IDENTITY: '/certificates/update_identity_certificate',
    ANDROID_ENTERPRISE: '/android-enterprise',
    SAFETYNET_SETTINGS: '/android_enterprise/safetynet',
    SUPPORT_MESSAGES: '/android_enterprise/support_message',
    REMOTE_VIEW: '/devices/remote-view',
    GET_FLAGS: '/feature_flags',
    GET_RESOURCES: '/resources',
    GET_GROUP_POLICY_FLAGS: '/feature_flags/group_policy',
    GET_PHONE_NUMBER: '/v4/devices/send_bulk_device_phone_by_sms',
    POST_BACKUP_CONTACTS: '/v4/devices/send_bulk_device_backup_contacts',
  } as const,
  PULSUS_BALDER: {
    GROUPS: '/groups',
    BATTERIES: '/batteries',
    MONTHS: '/map_history',
    PERIOD: '/period_available',
  } as const,
  PULSUS_FREYR: {
    GROUPS: '/group',
    GROUPS_BY_ID: '/group/by_id',
    APPLICATIONS: '/application',
    GOOGLE_LINKED_ACCOUNT: '/chrome',
    PLAY_INTEGRITY: '/integrity/settings',
  } as const,
  PULSUS_VALQUIRIA: {
    GEOFENCES_AREAS: '/geofence_areas',
    UPDATE_BLOCK_RULE: '/geofence_areas/rule',
    GEOFENCE_FILE_UPLOAD: '/geofence_areas/upload',
    GEOFENCE_STANDARD_CSV_DOWNLOAD: '/geofence_areas/download',
    GEOFENCES_UPDATE_GLOBAL: '/geofence_areas/geofence',
    GEOFENCES_ACTIONS: '/actions',
    ACTION: '/action',
    GROUP_POLICY: '/group_policy',
    GET_ADMIN_EMAILS: '/administrators/get_emails',
  } as const,
} as const;
