import { type ReactElement, useEffect, useState } from 'react';
import { EuiSwitch, EuiToolTip } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Skeleton } from '@mui/material';
import { useGeofenceContext } from 'modules/management/contexts/GeofenceContext';
import { IconFenceInFenceOut } from 'icons';
import imageMonitor from 'assets/images/monitor.svg';
import imageMultipleDevices from 'assets/images/multiple-devices.svg';
import * as S from './FenceConfigurationComponent.styles';
import { ComponentRubyRadioButtons } from 'modules/core/components';
import { SendEmailsModalComponent } from '../SendEmailsModal/SendEmailsModalComponent';
import type { Languages } from './FenceConfigurationComponent.contracts';
import { AdvancedFlag } from 'components/flags-advanced/component-advanced-flag';
import { useUserContext } from 'modules/core/contexts/user';
import { HelperAuthentication } from 'helpers';

export const FenceConfigurationComponent = (): ReactElement => {
  const [isSendEmailsOpen, setIsSendEmailsOpen] = useState(false);
  const [language, setLanguage] = useState<Languages>({ 'pt-BR': false, en: false, es: false });

  const { t } = useTranslation('translation', { keyPrefix: 'geofence.modal_edit.step2' });

  const { fields, setFields, loading, serverTrigger } = useGeofenceContext();

  const { permissions } = useUserContext();
  const canEditGroupPolicy = permissions?.group_policies && HelperAuthentication.decodedCanEditGroupPolicy();

  const handleBlockDeviceSwitch = (targetValue: boolean): void => {
    setFields({
      ...fields,
      blockDevice: targetValue === serverTrigger?.blockDevice ? serverTrigger.blockDevice : targetValue,
      fenceIn: targetValue === serverTrigger?.blockDevice ? serverTrigger.fenceIn : targetValue === false,
      fenceOut: targetValue === serverTrigger?.blockDevice ? serverTrigger.fenceOut : targetValue === true,
      both: targetValue === serverTrigger?.blockDevice ? serverTrigger.both : false,
    });
  };

  const handleAlertEmail = (targetValue: boolean): void => {
    setFields({
      ...fields,
      emailAlert: targetValue === serverTrigger?.emailAlert ? serverTrigger.emailAlert : targetValue,
    });
  };

  const handleSoundAlert = (targetValue: boolean): void => {
    setFields({
      ...fields,
      soundAlert: targetValue === serverTrigger?.soundAlert ? serverTrigger.soundAlert : targetValue,
    });
  };

  const changeCheckbox = (targetValue): void => {
    setFields({
      ...fields,
      soundAlertAction: targetValue === serverTrigger?.soundAlertAction ? serverTrigger?.soundAlertAction : targetValue,
    });
  };

  const changeEmailAlertLanguage = (emailValue) => {
    let targetValue = emailValue;
    Object.entries(language).forEach(([key, value]) => {
      if (value) {
        targetValue = key;
      }
    });

    if (emailValue == '') {
      setFields({
        ...fields,
        emailAlertLanguage: targetValue,
      });
    } else {
      setLanguage({ 'pt-BR': emailValue == 'pt-BR', en: emailValue == 'en', es: emailValue == 'es' });
    }
  };

  const changeEmailButton = (): string => {
    let configuredEmailsLabel = t('emailButton');

    if (fields.adminEmails.length > 0) {
      if (fields.adminEmails.length == 1) {
        configuredEmailsLabel = t('oneConfiguredEmail');
      } else {
        configuredEmailsLabel = `${fields.adminEmails.length} e-mails configurados`;
      }
    }

    return configuredEmailsLabel;
  };

  const showFenceLabel = (): string => {
    if (fields.fenceOut) {
      return t('alerts.fenceOut');
    } else if (fields.fenceIn) {
      return t('alerts.fenceIn');
    } else {
      return '';
    }
  };

  const optionsType = [
    { id: 'CONTINUED', text: t('alerts.continued') },
    { id: 'ONE', text: t('alerts.one') },
  ];

  useEffect(() => {
    changeEmailAlertLanguage('');
  }, [language]);

  useEffect(() => {
    if (fields.emailAlertLanguage) {
      changeEmailAlertLanguage(fields.emailAlertLanguage);
    }
  }, [fields.emailAlertLanguage]);

  useEffect(() => {
    if (fields.both) {
      setFields({
        ...fields,
        soundAlert: false,
      });
    }
  }, [fields.both]);

  return (
    <S.Container>
      <S.ActionFields>
        <h3>{t('subtitle')}</h3>
        {loading ? (
          <Skeleton variant="rectangular" width={600} height={90} />
        ) : (
          <S.WrapperActions>
            <S.Action>
              <S.Subtitle>
                <img data-testid="imageMonitor" src={imageMonitor} alt="Monitor" />
                <h4>{t('administrative')}</h4>
              </S.Subtitle>
              <S.StyledSwitch label={t('switchers.registerEvent')} disabled checked onChange={() => null} />
              <EuiSwitch
                label={t('switchers.email')}
                checked={fields.emailAlert}
                disabled={!canEditGroupPolicy}
                onChange={(event) => handleAlertEmail(event.target.checked)}
              />
              {fields.emailAlert && (
                <>
                  <S.EmailButton onClick={() => setIsSendEmailsOpen(true)} iconType={'plusInCircle'} disabled={!canEditGroupPolicy}>
                    {changeEmailButton()}
                  </S.EmailButton>
                  <S.LanguageContainer>
                    <p>{t('language')}</p>
                    <S.LanguageGroup>
                      <S.LanguageButton
                        disabled={!canEditGroupPolicy}
                        hasActiveFilters={language['pt-BR']}
                        onClick={() => setLanguage({ 'pt-BR': true, en: false, es: false })}
                      >
                        PT
                      </S.LanguageButton>
                      <S.LanguageButton
                        disabled={!canEditGroupPolicy}
                        hasActiveFilters={language.en}
                        onClick={() => setLanguage({ 'pt-BR': false, en: true, es: false })}
                      >
                        EN
                      </S.LanguageButton>
                      <S.LanguageButton
                        disabled={!canEditGroupPolicy}
                        hasActiveFilters={language.es}
                        onClick={() => setLanguage({ 'pt-BR': false, en: false, es: true })}
                      >
                        ES
                      </S.LanguageButton>
                    </S.LanguageGroup>
                  </S.LanguageContainer>
                </>
              )}
            </S.Action>
            <S.Line />
            <S.Action className="column-right">
              <S.Subtitle>
                <img data-testid="imageMultipleDevices" src={imageMultipleDevices} alt="Multiple Devices" />
                <h4>{t('onDevices')}</h4>
              </S.Subtitle>
              <EuiSwitch
                label={t('switchers.blockDevice')}
                checked={fields.blockDevice}
                onChange={(event) => handleBlockDeviceSwitch(event.target.checked)}
                disabled={!canEditGroupPolicy}
              />
              <S.ContainerFields>
                <EuiSwitch
                  disabled={fields.both || !canEditGroupPolicy}
                  style={{ marginBottom: '10px' }}
                  label={t('alerts.sound')}
                  checked={fields.soundAlert}
                  onChange={(e) => handleSoundAlert(e.target.checked)}
                />
                <S.FenceLabel>{showFenceLabel()}</S.FenceLabel>
                {fields.soundAlert && (
                  <ComponentRubyRadioButtons
                    row
                    selected={fields.soundAlertAction}
                    onClick={(value) => changeCheckbox(value)}
                    options={optionsType}
                  />
                )}
                <AdvancedFlag flags={[{ isPulsusAgent: true, version: '4.9.58' }]} styles={{ marginLeft: '50px' }} />
              </S.ContainerFields>
            </S.Action>
          </S.WrapperActions>
        )}
      </S.ActionFields>
      <S.TriggerFields>
        {loading ? (
          <Skeleton variant="rectangular" width={600} height={90} />
        ) : (
          <S.WrapperTriggers>
            <h3>{t('options.title')}</h3>
            <S.Soon className="triggers">
              <S.RetangleTriggerOption highlight>
                <LogoutIcon style={{ color: 'white', width: 18, height: 18 }} />
              </S.RetangleTriggerOption>

              <S.StyledRadio
                id="radioFenceOut"
                label={t('options.fenceOut')}
                checked={fields.fenceOut}
                onChange={(e) => setFields({ ...fields, fenceOut: e.target.checked, fenceIn: false, both: false })}
                disabled={!canEditGroupPolicy}
              />
            </S.Soon>
            <S.Soon className="triggers">
              <S.RetangleTriggerOption highlight>
                <LoginIcon style={{ color: '#FFFFFF', width: 18, height: 18 }} />
              </S.RetangleTriggerOption>

              <S.StyledRadio
                id="radioFenceIn"
                label={t('options.fenceIn')}
                checked={fields.fenceIn}
                onChange={(e) => setFields({ ...fields, fenceIn: e.target.checked, fenceOut: false, both: false })}
                disabled={!canEditGroupPolicy}
              />
            </S.Soon>
            <S.Soon className="triggers">
              <S.RetangleTriggerOption highlight={fields.blockDevice === false}>
                <IconFenceInFenceOut />
              </S.RetangleTriggerOption>
              <S.StyledRadio
                id="radioBoth"
                label={t('both_options')}
                disabled={fields.blockDevice === true || !canEditGroupPolicy}
                checked={fields.both}
                onChange={(e) => setFields({ ...fields, fenceOut: false, fenceIn: false, both: e.target.checked })}
              />
            </S.Soon>
          </S.WrapperTriggers>
        )}
      </S.TriggerFields>
      <SendEmailsModalComponent isOpen={isSendEmailsOpen} close={() => setIsSendEmailsOpen(false)} />
    </S.Container>
  );
};
