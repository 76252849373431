export class ContactsLists {
  public list: List[];
  public totalItems: number;

  constructor(data: ContactsLists.Server) {
    this.list = data.page_items.map((item) => ({
      identity: { id: String(item.id), name: item.displayname },
      description: item.description,
      quantity: item.contacts_count,
      status: item.upload_status,
      fileName: item.file_name,
      canEdit: item.can_edit,
    }));
    this.totalItems = data.total_items;
  }
}

export namespace ContactsLists {
  export type Server = {
    total_items: number;
    page_items: [
      {
        id: number;
        principaluri: string;
        displayname: string;
        uri: string;
        description: string;
        file_name: string;
        upload_status: string;
        contacts_count: number;
        can_edit: boolean;
      },
    ];
  };
}

export type List = {
  identity: ListIdentity;
  description: string;
  quantity: number;
  status: string;
  fileName: string;
  canEdit: boolean;
};

export type ListIdentity = {
  id: string;
  name: string;
};
