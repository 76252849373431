import React, { useEffect, useMemo, useState } from 'react';
import { EuiButtonEmpty, EuiPopover, EuiSpacer, EuiSwitch, EuiTab } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import * as style from './component-certificates-list.style';
import type { CerticateDataProps, ComponentCertificatesListProps, ItemListProps, CerticateDataItemProps } from '../page-certificate.contracts';
import { useUserContext } from 'modules/core/contexts/user';
import { HelperAuthentication } from 'helpers';

export function ComponentCertificatesList({ isOpenFinishedModal, authorityEnabled, identityEnabled }: ComponentCertificatesListProps) {
  const { t } = useTranslation();
  const [certificates, set_certificates] = useState([]);
  const [message, set_message] = useState('');
  const [dropdown_index, set_dropdown_index] = useState(-1);
  const [dropdown_index_apps, set_dropdown_index_apps] = useState(-1);
  const [appsList, setAppsList] = useState<never | string[]>([]);

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const { permissions } = useUserContext();
  const canEditGroupPolicy = permissions?.group_policies && HelperAuthentication.decodedCanEditGroupPolicy();

  useEffect(() => {
    if (certificates.length > 0) {
      set_message('');
    }
  }, [certificates]);

  useEffect(() => {
    set_message('');
    servicePulsusWeb
      .fetchAllCertificates()
      .then((items) => {
        set_certificates(items);
        if (certificates.length > 0) {
          set_message('');
        } else {
          set_message(t('pages.certificate.certificate_empty'));
        }
      })
      .catch(() => {
        set_certificates([]);
        set_message('Erro ao cerregar os certificados.');
      });
  }, [certificates.length]);
  useEffect(() => {
    servicePulsusWeb.fetchAllCertificates().then((items) => {
      set_certificates(items);
    });
  }, [isOpenFinishedModal]);

  useEffect(() => {
    setAppsList([]);
  }, []);

  const onChangeEnabled = (item: ItemListProps) => {
    if (item.kind === 'identity') {
      servicePulsusWeb.editCertificateIdentity(item.id, item.kind, !item.enabled).then(() => {
        servicePulsusWeb.fetchAllCertificates().then((items) => {
          set_certificates(items);
        });
      });
    } else {
      servicePulsusWeb.editCertificateCA(item.id, item.kind, !item.enabled).then(() => {
        servicePulsusWeb.fetchAllCertificates().then((items) => {
          set_certificates(items);
        });
      });
    }
  };

  const removeCertificate = (item: ItemListProps) => {
    set_dropdown_index(-1);
    servicePulsusWeb.deleteCertificate(item.id, item.kind).then(() => {
      servicePulsusWeb.fetchAllCertificates().then((items) => {
        set_certificates(items);
      });
    });
  };

  const handleOptionsDropdown = (index: number) => {
    set_dropdown_index(index);
    if (dropdown_index === index) {
      set_dropdown_index(-1);
    }
  };

  const handleAppsDropdown = (index: number) => {
    set_dropdown_index_apps(index);
    if (dropdown_index_apps === index) {
      set_dropdown_index_apps(-1);
    }
  };

  const closePopover = () => {
    set_dropdown_index(-1);
  };

  const closePopoverApps = () => {
    set_dropdown_index_apps(-1);
  };

  const renderCertificateBadge = (kind: string) => {
    return (
      <style.CertificateBadgeContainer>
        <style.Badge isAuthority={kind === 'authority'}>
          {kind === 'authority' ? t('pages.certificate.certificate_type') : 'USER IDENTITY'}
        </style.Badge>
      </style.CertificateBadgeContainer>
    );
  };

  const renderOptionsButton = (index: number) => {
    return (
      <style.OptionsButton iconType="arrowDown" iconSide="right" onClick={() => handleOptionsDropdown(index)} size="s">
        {t('pages.certificate.label_options')}
      </style.OptionsButton>
    );
  };

  const renderCertificateData = (item: CerticateDataProps, index: number) => {
    return (
      <React.Fragment key={`${item.kind}-${item.id}`}>
        <style.CertificateContainer>
          <div className="switch-container">
            <EuiSwitch label={item.name} checked={item.enabled} onChange={() => onChangeEnabled(item)} disabled={!canEditGroupPolicy} />
            {item.kind === 'identity' && (
              <p>
                {/* Acesso silencioso para <b>9 aplicativos</b> */}
                <EuiPopover
                  className="certificates-list-popover"
                  button={
                    <EuiButtonEmpty onClick={() => handleAppsDropdown(index)}>{t('pages.certificate.label_options_certificate_apps')}</EuiButtonEmpty>
                  }
                  isOpen={dropdown_index_apps === index}
                  closePopover={closePopoverApps}
                  panelPaddingSize="none"
                  repositionOnScroll
                  ownFocus={false}
                  anchorPosition="downLeft"
                >
                  {dropdown_index_apps === index && (
                    <style.ContextMenuApps>
                      <div className="title">
                        <h4>{t('pages.certificate.label_options_certificate_title')}</h4>
                      </div>
                      <ul>
                        {item?.applications.length ? (
                          item?.applications?.map((appItem: CerticateDataItemProps) => {
                            if (appItem) {
                              return <li key={item.id}>{appItem?.name}</li>;
                            }
                            return '';
                          })
                        ) : (
                          <li>{t('pages.certificate.label_options_certificate_subttile')}</li>
                        )}
                      </ul>
                    </style.ContextMenuApps>
                  )}
                </EuiPopover>
              </p>
            )}
          </div>

          {renderCertificateBadge(item.kind)}
          <EuiPopover
            className="certificates-list-popover"
            button={renderOptionsButton(index)}
            isOpen={dropdown_index === index}
            closePopover={closePopover}
            panelPaddingSize="none"
            repositionOnScroll
            ownFocus={false}
            anchorPosition="downLeft"
          >
            {dropdown_index === index && (
              <style.ContextMenu
                initialPanelId={0}
                panels={[
                  {
                    id: 0,
                    title: t('pages.certificate.label_options_certificate'),
                    items: [
                      {
                        name: t('pages.certificate.label_options_certificate_del'),
                        icon: 'trash',
                        onClick: () => {
                          removeCertificate(item);
                        },
                        disabled: !canEditGroupPolicy,
                      },
                    ],
                  },
                ]}
              />
            )}
          </EuiPopover>
        </style.CertificateContainer>
        <style.SeparationLine />
      </React.Fragment>
    );
  };

  const mapAllCertificates = () => {
    return <>{certificates.map((item, index) => renderCertificateData(item, index))}</>;
  };

  const filterCaCertificate = () => {
    const certificates_ca = certificates.filter((item: CerticateDataProps) => item.kind === 'authority');
    return <>{certificates_ca.length > 0 && certificates_ca.map((item, idx) => renderCertificateData(item, idx))}</>;
  };

  const filterUserIdentity = () => {
    const user_identity = certificates.filter((item: CerticateDataProps) => item.kind === 'identity');
    return <>{user_identity.length > 0 && user_identity.map((item, idx) => renderCertificateData(item, idx))}</>;
  };

  const createTabsContents = () => {
    const availableTabs: any[] = [];
    if (authorityEnabled && identityEnabled) {
      availableTabs.push({
        id: 'all_certificates',
        name: t('pages.certificate_list.menu_all'),
        content: (
          <>
            <EuiSpacer />
            <style.TabContent>{mapAllCertificates()}</style.TabContent>
          </>
        ),
      });
    }

    if (authorityEnabled) {
      availableTabs.push({
        id: 'ca_certificates',
        name: t('pages.certificate_list.menu_ca'),
        content: (
          <>
            <EuiSpacer />
            <style.TabContent>{filterCaCertificate()}</style.TabContent>
          </>
        ),
      });
    }

    if (identityEnabled) {
      availableTabs.push({
        id: 'user_identity',
        disabled: false,
        name: t('pages.certificate_list.menu_ui'),
        content: (
          <>
            <EuiSpacer />
            <style.TabContent>{filterUserIdentity()}</style.TabContent>
          </>
        ),
      });
    }
    return availableTabs;
  };

  const tabs_content = createTabsContents();
  const [selected_tab_id, set_selected_tab_id] = useState('all_certificates');

  const selectedTabsContent = useMemo(() => {
    return tabs_content.find((obj) => obj.id === selected_tab_id)?.content;
  }, [selected_tab_id, tabs_content, certificates]);

  const onSelectedTabChanged = (id: string) => {
    set_selected_tab_id(id);
  };

  return (
    <>
      <style.TabsContainer>
        <style.TabsSelector>
          {tabs_content.map((tab) => (
            <EuiTab key={tab.id} onClick={() => onSelectedTabChanged(tab.id)} isSelected={tab.id === selected_tab_id} disabled={tab.disabled}>
              {tab.name}
            </EuiTab>
          ))}
        </style.TabsSelector>
      </style.TabsContainer>
      <style.TextPrimary>{message}</style.TextPrimary>
      {selectedTabsContent}
    </>
  );
}
