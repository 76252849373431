import * as jose from 'jose';
import { HelperStorage } from './helper-storage';

export class HelperAuthentication {
  static key() {
    return '@jwt_token';
  }

  static getJwtToken() {
    return HelperStorage.fetch(this.key());
  }

  static decode(jwt_token) {
    const decode = jose.decodeJwt(jwt_token);
    if (decode) {
      return decode;
    }
    return false;
  }

  static decodeCustom(token) {
    return jose.decodeJwt(token);
  }

  static isAuthenticated() {
    const jwt_token = this.getJwtToken();

    if (jwt_token) {
      const decoded = this.decode(jwt_token);

      if (decoded && Date.now() <= decoded.exp * 1000) {
        return true;
      }
    }

    HelperAuthentication.logout();

    return false;
  }

  static login(token) {
    HelperStorage.save(this.key(), token);
  }

  static logout() {
    HelperStorage.delete(this.key());
  }

  static token() {
    return HelperStorage.fetch(this.key());
  }

  static bearer() {
    const token = new URLSearchParams(window.location.search).get('token') || HelperStorage.fetch(this.key());
    return `Bearer ${token}`;
  }

  static decodingEmail() {
    const jwt_token = HelperStorage.fetch(this.key());
    const decoded = this.decode(jwt_token);
    return decoded.email;
  }

  static decodingTenantId() {
    const jwt_token = HelperStorage.fetch(this.key());
    const decoded = this.decode(jwt_token);
    return decoded.tenant_id;
  }

  static decodedGroupPolicyId() {
    const jwt_token = HelperStorage.fetch(this.key());
    const decoded = this.decode(jwt_token);
    return decoded.group_policy_id;
  }

  static decodedSchemaName() {
    const jwt_token = HelperStorage.fetch(this.key());
    const decoded = this.decode(jwt_token);
    return decoded.schema_name;
  }

  static decodedAdminIsRestricted() {
    const jwt_token = HelperStorage.fetch(this.key());
    const decoded = this.decode(jwt_token);
    return decoded.admin_is_restricted;
  }

  static decodedCanEditGroupPolicy() {
    const jwt_token = HelperStorage.fetch(this.key());
    const decoded = this.decode(jwt_token);
    return decoded.can_edit_group_policy;
  }

  static decodeJwtFrom(token) {
    const decoded = this.decodeCustom(token);
    return decoded;
  }
}
