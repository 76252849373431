/* eslint-disable react/jsx-no-useless-fragment */
import { DialogComponent } from 'components';
import type { EuiComboBoxOptionOption } from '@elastic/eui';
import { EuiHealth, EuiButtonEmpty, EuiComboBox } from '@elastic/eui';
import { useEffect, useMemo, useState } from 'react';
import Lottie from 'lottie-react';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import AndroidIcon from 'assets/images/android-icon.png';
import animation_logo_pulsus_grey from 'assets/animations/animation-logo-pulsus-grey.json';
import { Group } from 'entities/group';
import { EditDeviceFormAtt } from 'modules/operational/entities/devices/editDeviceFormAtt.entity';
import { HelperDevice } from 'modules/operational/pages/devices/PageDevices.helper';
import type { DevicesStatus } from 'modules/operational/pages/devices/PageDevices.contracts';
import { StatusCode } from 'modules/operational/pages/devices/PageDevices.contracts';
import { useTypedTranslation } from 'modules/core/hooks';
import type { CustomFields, EditDeviceAPIBody, EditDeviceModalProps, InputProps } from './EditDeviceModalComponent.contracts';
import * as S from './EditDeviceModalComponent.styles';
import type { APIDeviceDetails } from 'modules/operational/entities/devices';
import { FeatureFlagCheckerComponent } from 'modules/core/components/FeatureFlagChecker/FeatureFlagCheckerComponent';
import { useUserContext } from 'modules/core/contexts/user';

export function EditDeviceModalComponent({
  deviceId,
  closeModal,
  refreshDevicesList,
  setToastType,
  groups,
  hasCustomFieldResource,
}: EditDeviceModalProps) {
  const { t } = useTypedTranslation<'devices.edit_device_modal'>('devices.edit_device_modal');

  const [selectedGroup, setSelectedGroup] = useState<Group[]>([]);

  const [users, setUsers] = useState<EuiComboBoxOptionOption[] | undefined>(undefined);
  const [userSelected, setUserSelected] = useState<EuiComboBoxOptionOption[] | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [deviceDetails, setDeviceDetails] = useState<APIDeviceDetails>();
  const [groupError, setGroupError] = useState({ error: '', hasError: false });
  const [userCustomFields, setUserCustomFields] = useState<CustomFields[]>([]);
  const [statusColor, setStatusColor] = useState('');
  const [statusText, setStatusText] = useState('');
  const [firstName, setFirstName] = useState<InputProps>({ isRequired: false, value: '', error: '', hasError: false });
  const [lastName, setLastName] = useState<InputProps>({ isRequired: false, value: '', error: '', hasError: false });
  const [exchangeUserName, setExchangeUserName] = useState<InputProps>({ isRequired: false, value: '', error: '', hasError: false });
  const [email, setEmail] = useState<InputProps>({ isRequired: false, value: '', error: '', hasError: false });
  const [userIdentifier, setUserIdentifier] = useState<InputProps>({ isRequired: false, value: '', error: '', hasError: false });

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const { featureFlags } = useUserContext();

  const setInitialFormStates = (deviceModalItems) => {
    if (deviceModalItems) {
      const { id, identifier, group, user, custom_field_labels } = deviceModalItems;

      const groupLocal = [new Group(group.name, group.work_profile_policy_name, group.id)];

      const formAttributes = new EditDeviceFormAtt(
        id,
        identifier,
        groupLocal,
        user?.first_name,
        user?.last_name,
        user?.email,
        user?.exchange_user_name,
        user?.identifier,
        user?.custom_field_infos
      );

      const { color, text } = HelperDevice.findStatusAttributes(StatusCode[deviceModalItems.status] as DevicesStatus);
      setStatusColor(color);
      setStatusText(text);

      setSelectedGroup(groupLocal || []);

      setFirstName({ ...firstName, isRequired: group.first_name_required, value: formAttributes.firstName || '' });
      setLastName({ ...lastName, isRequired: group.last_name_required, value: formAttributes.lastName || '' });
      setEmail({ ...email, isRequired: group.email_required, value: formAttributes.email || '' });
      setUserIdentifier({ ...userIdentifier, isRequired: group.identifier_required, value: formAttributes.userIdentifier || '' });
      setExchangeUserName({ ...exchangeUserName, value: formAttributes.exchangeUserName || '' });

      if (custom_field_labels.length > 0) {
        const customFields: CustomFields[] = custom_field_labels.map((item) => {
          return { id: item.id, label: item.label, value: '', isRequired: item.required, error: '', hasError: false };
        });

        if (formAttributes.userCustomFields) {
          const customFieldValues = formAttributes.userCustomFields.map((item) => ({ id: item.custom_field.id, value: item.value }));

          const combinedCustomFields: CustomFields[] = customFields.map((field: CustomFields) => {
            const matchingIds = customFieldValues.find((value) => value.id === field.id);
            return {
              id: field.id,
              label: field.label,
              value: matchingIds?.value || '',
              isRequired: field.isRequired,
              hasError: false,
              error: '',
            };
          });
          combinedCustomFields.sort((a, b) => a.id - b.id);
          setUserCustomFields(combinedCustomFields);
        } else {
          customFields.sort((a, b) => a.id - b.id);
          setUserCustomFields(customFields);
        }
      }
    }
  };

  const getDeviceDetails = async () => {
    try {
      const device = await serviceLoki.getDeviceDetailsById(deviceId);

      if (device.custom_field_labels.length > 0) {
        if (!hasCustomFieldResource) {
          device.custom_field_labels = [];

          if (device.user) {
            device.user.custom_field_infos = [];
          }
        }
      }

      setDeviceDetails(device);
      setInitialFormStates(device);

      const searchedUsers = await serviceLoki.getUsersList();

      const adaptSearchedUsers = searchedUsers.map((user) => ({ ...user, label: `${user.fullName} (${user.username})`, value: user.id }));
      setUsers(adaptSearchedUsers);

      if (device.user_session) {
        setUserSelected([{ label: device.user_session.username, value: device.user_session.user_id }]);
        return;
      }

      if (device?.user?.keycloak_id) {
        const preSelectedUser = adaptSearchedUsers?.find((user) => user.value === device.user?.keycloak_id);
        setUserSelected([{ label: preSelectedUser?.label!, value: preSelectedUser?.value }]);
      }
    } catch {
      setToastType('error');
    } finally {
      setLoading(false);
    }
  };

  const hasFF = useMemo(() => featureFlags.find((ff) => ff === 'PEM3506_auth_users'), [featureFlags]);

  useEffect(() => {
    getDeviceDetails();
  }, []);

  const submitForm = async () => {
    if (!selectedGroup) return;

    const data: EditDeviceAPIBody = {
      user_schema: {
        group_id: selectedGroup[0]?.id,
        user_id: (userSelected?.[0]?.value as string) || null!,
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        exchange_user_name: exchangeUserName.value,
        identifier: userIdentifier.value || '',
      },

      custom_fields: userCustomFields.map((item) => {
        return { custom_field_id: item.id, value: item.value };
      }),
    };

    if (validateForm(data)) {
      try {
        await serviceLoki.editDevice(deviceDetails!.id, data);
        setToastType('success');
        refreshDevicesList();
      } catch {
        setToastType('error');
      } finally {
        closeModal();
      }
    }
  };

  const validateForm = (data: EditDeviceAPIBody): boolean => {
    const { user_schema } = data;

    if (!user_schema.group_id) {
      return false;
    }

    if (!validateEmail(user_schema.email) && !hasFF) {
      return false;
    }

    return true;
  };

  const onComboBoxChange = (item) => {
    setSelectedGroup(item);

    if (item.length > 0) {
      setGroupError({ hasError: false, error: '' });

      if (item[0].id !== deviceDetails?.group.id) {
        const customFieldsLocal = userCustomFields.map((itemCustom) => ({
          ...itemCustom,
          isRequired: false,
        }));

        setUserCustomFields(customFieldsLocal);
      } else {
        setInitialFormStates(deviceDetails);
      }
    } else {
      setGroupError({ hasError: true, error: t('error_required') });
    }
  };

  const onChange = (selectedOptions) => {
    setFirstName((prev) => ({
      ...prev,
      value: selectedOptions?.[0]?.firstName || '',
    }));

    setLastName((prev) => ({
      ...prev,
      value: selectedOptions?.[0]?.lastName || '',
    }));

    setEmail((prev) => ({
      ...prev,
      value: selectedOptions?.[0]?.email || '',
    }));

    setUserSelected(selectedOptions);
  };

  const validateEmail = (emailForm: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(emailForm) && emailForm !== '') {
      setEmail({ ...email, value: emailForm, error: t('error_mail'), hasError: true });
      return false;
    }
    setEmail({ ...email, value: emailForm, error: '', hasError: false });
    return true;
  };

  const onEmailChange = (value: string) => {
    validateEmail(value);
  };

  const handleCustomFieldChange = (newValue: string, idx: number) => {
    const updatedUserCustomFields = [...userCustomFields];
    updatedUserCustomFields[idx].value = newValue;
    updatedUserCustomFields[idx].hasError = false;

    setUserCustomFields(updatedUserCustomFields);
  };

  const renderUserFields = () => (
    <>
      <S.EditDeviceItem>
        <S.EuiFormRowStyled label={`${t('name_label')}${firstName.isRequired ? '*' : ''}`} isInvalid={firstName.hasError} error={firstName.error}>
          <S.EuiFieldTextStyled
            placeholder={t('name_placeholder')}
            type="match_only_text"
            value={firstName.value}
            onChange={(e) => setFirstName({ ...firstName, hasError: false, value: e.target.value })}
          />
        </S.EuiFormRowStyled>
      </S.EditDeviceItem>
      <S.EditDeviceItem>
        <S.EuiFormRowStyled label={`${t('last_name_label')}${lastName.isRequired ? '*' : ''}`} isInvalid={lastName.hasError} error={lastName.error}>
          <S.EuiFieldTextStyled
            placeholder={t('last_name_placeholder')}
            value={lastName.value}
            onChange={(e) => setLastName({ ...lastName, hasError: false, value: e.target.value })}
          />
        </S.EuiFormRowStyled>
      </S.EditDeviceItem>
      <S.EditDeviceItem>
        <S.EuiFormRowStyled label={`${t('email_label')}${email.isRequired ? '*' : ''}`} isInvalid={email.hasError} error={email.error}>
          <S.EuiFieldTextStyled
            placeholder={t('email_placeholder')}
            type="email"
            value={email.value}
            onChange={(e) => onEmailChange(e.target.value)}
            isInvalid={email.hasError}
          />
        </S.EuiFormRowStyled>
      </S.EditDeviceItem>
      <S.EditDeviceItem>
        <S.EuiFormRowStyled label={`ID:${userIdentifier.isRequired ? '*' : ''}`} isInvalid={userIdentifier.hasError} error={userIdentifier.error}>
          <S.EuiFieldTextStyled
            placeholder={t('user_id_placeholder')}
            value={userIdentifier.value}
            onChange={(e) => setUserIdentifier({ ...userIdentifier, hasError: false, value: e.target.value })}
          />
        </S.EuiFormRowStyled>
      </S.EditDeviceItem>
    </>
  );

  const isInvalidUsername = !!deviceDetails?.user_session;

  return (
    <DialogComponent
      padding="30px 40px 20px 40px"
      overflow="auto"
      width="100%"
      maxHeight="90vh"
      maxWidth="560px"
      closeOnClickOutside
      open={!!deviceId}
      onClose={() => closeModal()}
    >
      {loading && (
        <S.PlaceHolder hasFF={!!hasFF}>
          <Lottie
            data-testid="animation_logo_pulsus"
            animationData={animation_logo_pulsus_grey}
            style={{
              width: '500px',
              height: '340px',
              pointerEvents: 'none',
              margin: '0',
            }}
          />
        </S.PlaceHolder>
      )}
      <S.EditDeviceContainer>
        <S.DeviceInfo>
          <S.AndroidIconContainer>
            <S.AndroidIcon src={AndroidIcon} alt="Android" />
          </S.AndroidIconContainer>
          <S.ItemId>{`ID-${deviceId}`}</S.ItemId>
          <EuiHealth style={{ color: statusColor }} />
          <S.ItemStatus>{statusText}</S.ItemStatus>
        </S.DeviceInfo>
        <S.EditDeviceItem>
          <S.EuiFormRowStyled label="IMEI:">
            <S.EuiFieldTextStyled placeholder="IMEI" value={deviceDetails?.identifier} disabled />
          </S.EuiFormRowStyled>
        </S.EditDeviceItem>

        <S.EditDeviceItem>
          <S.EuiFormRowStyled label={`${t('group_label')}*`} isInvalid={groupError.hasError} error={groupError.error}>
            <EuiComboBox
              aria-label="Group selection"
              singleSelection={{ asPlainText: true }}
              placeholder={t('group_placeholder')}
              options={groups}
              selectedOptions={selectedGroup}
              onChange={onComboBoxChange}
              isClearable
              className="euiComboBox"
            />
          </S.EuiFormRowStyled>
        </S.EditDeviceItem>
        <FeatureFlagCheckerComponent featureFlag="PEM3506_auth_users" withoutSuperadmin fallback={renderUserFields()}>
          <S.EditDeviceItem>
            <S.EuiFormRowStyled
              label={t('username_label')}
              isInvalid={isInvalidUsername}
              error={isInvalidUsername ? t('user_in_use', { user: userSelected?.[0]?.label }) : ''}
            >
              <EuiComboBox
                singleSelection={true}
                placeholder={t('username_placeholder')}
                options={users}
                selectedOptions={userSelected}
                isLoading={loading}
                onChange={onChange}
                isClearable
                isInvalid={isInvalidUsername}
                isDisabled={loading || isInvalidUsername}
              />
            </S.EuiFormRowStyled>
          </S.EditDeviceItem>
        </FeatureFlagCheckerComponent>

        <FeatureFlagCheckerComponent featureFlag="PEM1815_custom_fields">
          {userCustomFields?.map((item, idx) => (
            <S.EditDeviceItem key={item.id}>
              <S.EuiFormRowStyled label={`${item.label}${item.isRequired ? '*' : ''}`} isInvalid={item.hasError} error={item.error}>
                <S.EuiFieldTextStyled placeholder={item.label} value={item.value} onChange={(e) => handleCustomFieldChange(e.target.value, idx)} />
              </S.EuiFormRowStyled>
            </S.EditDeviceItem>
          ))}
        </FeatureFlagCheckerComponent>

        <FeatureFlagCheckerComponent
          featureFlag="PEM3506_auth_users"
          withoutSuperadmin
          fallback={
            <S.EditDeviceItem>
              <S.EuiFormRowStyled label={t('user_exchange_label')}>
                <S.EuiFieldTextStyled
                  placeholder={t('user_exchange_placeholder')}
                  value={exchangeUserName.value}
                  onChange={(e) => setExchangeUserName({ ...exchangeUserName, value: e.target.value })}
                />
              </S.EuiFormRowStyled>
            </S.EditDeviceItem>
          }
        >
          <></>
        </FeatureFlagCheckerComponent>

        <S.RequiredText>{t('required_text')}</S.RequiredText>
        <S.EditDeviceItem>
          <S.ButtonsContainer>
            <EuiButtonEmpty onClick={() => closeModal()}>{t('cta_cancel')}</EuiButtonEmpty>
            <S.Button onClick={submitForm}>{t('cta_save')}</S.Button>
          </S.ButtonsContainer>
        </S.EditDeviceItem>
      </S.EditDeviceContainer>
    </DialogComponent>
  );
}
