import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiInMemoryTable, EuiToolTip, EuiIcon } from '@elastic/eui';
import { useQuery } from 'hooks/use-query';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { TextEllipsisComponent } from 'modules/core/components/TextEllipsis/TextEllipsisComponent';
import { ServicePulsusValquiria } from 'services/ServicePulsusValquiria';
import type { EventRegisterType } from 'modules/management/entities/geofence/Register.entity';
import { Register } from 'modules/management/entities/geofence/Register.entity';
import { HelperAuthentication } from 'helpers';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import * as S from './RegisterComponent.styles';
import type { Paginate } from './RegisterComponent.contracts';

export const RegisterComponent = ({ embedded }: { embedded?: boolean }): ReactElement => {
  const query = useQuery();
  const language = query.get('language');
  const [loading, setLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<Toast[]>([]);
  const [paginate, setPaginate] = useState<Paginate>({ page: 0, registers: [], totalPages: 0 });

  const { t } = useTranslation('translation', { keyPrefix: 'geofence.table_registers' });

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
      moment.locale(language);
    }
    makeApiCall(1);
  }, []);

  const makeApiCall = async (searchPage: number) => {
    try {
      setLoading(true);
      const response = await ServicePulsusValquiria.getRegisters(HelperAuthentication.decodingTenantId(), searchPage);
      const registers = response.registers as unknown as Register.Server[];
      const adaptee = registers.map((register) => new Register(register));

      setPaginate({ ...paginate, page: response.page, registers: adaptee, totalPages: response.totalPages });
    } catch (err) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('error.title'),
          text: t('error.description'),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleClickEmbeddedLink = (deviceId: string) => {
    if (window.self !== window.top) {
      window.parent.location.href = `${process.env.REACT_APP_RUBY_LINK}devices/${deviceId}`;
    }
  };

  const getLabelForBlockDevice = (status: boolean | '-'): string => {
    if (status === true) return t('rows.blockDevice.active');

    if (status === false) return t('rows.blockDevice.inactive');

    return '-';
  };

  const columns: EuiBasicTableColumn<Register>[] = [
    {
      field: 'deviceId',
      name: t('rows.id'),
      width: '100px',
      render: (deviceId: string, fence: Register) => {
        return (
          <S.ActionsNameContainer>
            {embedded ? (
              <S.ActionsName onClick={() => handleClickEmbeddedLink(deviceId)} highlight={!!fence?.event}>
                {deviceId}
              </S.ActionsName>
            ) : (
              <S.ActionsNameRouter to={`/device-management/${deviceId}`} highlight={!!fence?.event}>
                {deviceId}
              </S.ActionsNameRouter>
            )}
          </S.ActionsNameContainer>
        );
      },
    },
    {
      field: 'userName',
      name: t('rows.userName'),
      width: '100px',
      align: 'center',
      render: (userName: string) => {
        return (
          <EuiToolTip position="top" content={userName}>
            <S.Text>
              <TextEllipsisComponent>
                <span>{userName}</span>
              </TextEllipsisComponent>
            </S.Text>
          </EuiToolTip>
        );
      },
    },
    {
      field: 'groupName',
      name: t('rows.groupName'),
      width: '130px',
      align: 'center',
      render: (groupName: number) => {
        return (
          <EuiToolTip position="top" content={groupName}>
            <S.Text style={{ textAlign: 'center' }}>
              <TextEllipsisComponent>
                <b>{groupName}</b>
              </TextEllipsisComponent>
            </S.Text>
          </EuiToolTip>
        );
      },
    },
    {
      field: 'event',
      name: t('rows.event.label'),
      width: '70px',
      align: 'center',
      render: (event: EventRegisterType, record: any) => {
        const isAlert = record.fence === 'None' && record.event === 'OUTSIDE';
        return (
          <S.Text>
            <b>{event === 'INSIDE' ? t('rows.event.inside') : t('rows.event.outside')}</b>
            {isAlert && (
              <EuiToolTip position="top" content={t('rows.event.alertTooltip')}>
                <S.AlertIconContainer>
                  <EuiIcon type="alert" color="warning" size="m" />
                </S.AlertIconContainer>
              </EuiToolTip>
            )}
          </S.Text>
        );
      },
    },
    {
      field: 'registeredAt',
      name: t('rows.registeredAt'),
      width: '150px',
      align: 'center',
      render: (registeredAt: Date) => {
        return <S.Text>{moment(registeredAt).format('DD MMM YYYY HH:mm')}</S.Text>;
      },
    },
    {
      field: 'fence',
      name: t('rows.fence.label'),
      width: '100px',
      align: 'center',
      render: (fence: string) => {
        const displayFence = fence === 'None' ? t('rows.fence.noFence') : fence;
        return (
          <EuiToolTip position="top" content={displayFence}>
            <S.Badge color="subdued">
              <TextEllipsisComponent>{displayFence}</TextEllipsisComponent>
            </S.Badge>
          </EuiToolTip>
        );
      },
    },
    {
      field: 'blockDevice',
      name: t('rows.blockDevice.label'),
      width: '100px',
      align: 'center',
      render: (action: boolean | '-') => {
        return <S.BlockDeviceText>{getLabelForBlockDevice(action)}</S.BlockDeviceText>;
      },
    },
  ];

  const pagination = {
    initialPageSize: 25,
    hidePerPageOptions: true,
  };

  return (
    <>
      <S.Body>
        <S.WrapperTable>
          <EuiInMemoryTable
            itemId="id"
            loading={loading}
            items={paginate.registers}
            message={loading ? 'Buscando registros' : 'Sem itens encontrados'}
            columns={columns}
            pagination={pagination}
          />
        </S.WrapperTable>
      </S.Body>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
};
