export class Group {
  constructor(name, work_profile_policy_name, id, group_policy_id, total_devices) {
    this.name = name;
    this.label = name;
    this.work_profile_policy_name = work_profile_policy_name;
    this.id = id;
    this.group_policy_id = group_policy_id;
    this.total_devices = total_devices;
    this.className = '';
    this.disabled = false;
  }
}
